.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::-moz-selection {
  background: #97C221;
  color: #777D81;
}
::selection {
  background: #97C221;
  color: #777D81;
}
* {
  -webkit-tap-highlight-color: rgba(151, 194, 33, 0.1);
  box-sizing: border-box;
}
.page {
  background: #fff;
}
main {
  display: block;
}
html {
  -webkit-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.9375rem + 0.25
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 0.9375rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #777D81;
  font-family: "thierfeld-berg", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 400;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.6;
  margin: 0 auto;
  max-width: 160rem;
  position: relative;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(0.9375rem + 0.25
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125rem) {
  body {
    font-size: 1.1875rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1 {
  letter-spacing: calc((1.6875rem + 1.0625
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1.6875rem;
  color: #97C221;
  font-family: "thierfeld-berg", sans-serif;
  font-weight: 300;
  line-height: 1.35;
  margin: 0 0 0.53333333em;
}
@media (min-width: 20rem) {
  h1 {
    font-size: calc(1.6875rem + 1.0625
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125rem) {
  h1 {
    font-size: 2.75rem;
  }
}
h2,
.style_h2 {
  letter-spacing: calc((1.4375rem + 0.625
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1.4375rem;
  color: #777D81;
  font-family: "thierfeld-berg", sans-serif;
  font-weight: 300;
  line-height: 1.35;
  margin: 0 0 0.8em;
  padding-top: 0.53333333em;
}
@media (min-width: 20rem) {
  h2,
  .style_h2 {
    font-size: calc(1.4375rem + 0.625
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125rem) {
  h2,
  .style_h2 {
    font-size: 2.0625rem;
  }
}
h3,
.style_h3 {
  letter-spacing: calc((1.1875rem + 0.5
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #777D81;
  font-family: "thierfeld-berg", sans-serif;
  font-weight: 300;
  line-height: 1.35;
  margin: 0 0 0.53333333em;
  padding-top: 0.8em;
}
@media (min-width: 20rem) {
  h3,
  .style_h3 {
    font-size: calc(1.1875rem + 0.5
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125rem) {
  h3,
  .style_h3 {
    font-size: 1.6875rem;
  }
}
h4 {
  letter-spacing: calc((1.0625rem + 0.3125
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1.0625rem;
  color: #777D81;
  font-family: "thierfeld-berg", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.8em;
}
@media (min-width: 20rem) {
  h4 {
    font-size: calc(1.0625rem + 0.3125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125rem) {
  h4 {
    font-size: 1.375rem;
  }
}
h5 {
  letter-spacing: calc((1.0625rem + 0.1875
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1.0625rem;
  color: #777D81;
  font-family: "thierfeld-berg", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5 {
    font-size: calc(1.0625rem + 0.1875
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125rem) {
  h5 {
    font-size: 1.25rem;
  }
}
h6 {
  height: 0;
  margin: 0;
  overflow: hidden;
  width: 0;
}
p {
  margin: 0 0 0.8em;
}
a {
  color: #97C221;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #777D81;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(151, 194, 33, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
  top: -2.5rem;
}
a[tabindex="-1"][id]:after {
  content: "";
}
a.touch_link {
  color: #777D81;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #97C221;
  display: inline-block;
  margin-bottom: 0.4em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #777D81;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(151, 194, 33, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 700;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.8em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.6em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.6em;
}
table {
  border-bottom: 0.0625rem solid #d8d8d8;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 700;
  text-align: left;
}
td,
th {
  border-top: 0.0625rem solid #d8d8d8;
  padding: 0.53333333em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #f6f6f6;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.8em;
  padding: 0;
}
ol > li {
  padding-left: 1.6em;
  position: relative;
}
ol > li:before {
  color: #97C221;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 700;
  left: 0;
  line-height: 1.6;
  position: absolute;
  text-align: right;
  width: 1.2em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_rectangle.svg");
  margin: 0 0 0.8em;
  padding-left: 1.2em;
}
ul > li {
  padding-left: 0.4em;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list_entry dl {
  border-bottom: 0.0625rem solid #d8d8d8;
  margin: 0.8em 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list_entry dt {
  border-top: 0.0625rem solid #d8d8d8;
  font-weight: 700;
  padding: 0.8em 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.8em 0.8em 0.8em 0;
  }
}
.defined_list_entry dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dd {
    border-top: 0.0625rem solid #d8d8d8;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.8em 0.53333333em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.6em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.6em;
}
figure.floatright {
  float: right;
  margin-left: 1.6em;
}
figcaption {
  margin-top: 0.4em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
hr {
  border-bottom: 0.0625rem solid #d8d8d8;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.6em 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #777D81;
  box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.1875rem #97C221 inset, 0 0 0 0.1875rem rgba(151, 194, 33, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #b9bcbe;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #b9bcbe;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #b9bcbe;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #97C221;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #97C221;
  color: #fff;
  display: none;
  font-weight: 700;
  margin-right: 0.4em;
  padding: 0 0.4em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.1875rem + 0.25
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #777D81;
  font-family: "thierfeld-berg", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.8em;
  padding: 0.8em 0 0;
  width: 100%;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.1875rem + 0.25
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media (min-width: 78.125rem) {
  .legend {
    font-size: 1.4375rem;
  }
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: inline-block;
  margin-bottom: 0.4em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.0625rem solid #d8d8d8;
  border-radius: 0;
  box-shadow: 0 0 0 #d8d8d8 inset;
  color: #777D81;
  margin: 0 0 1.06666667em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #97C221;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #97C221;
  box-shadow: 0 0 0.1875rem #97C221 inset, 0 0 0 0.1875rem rgba(151, 194, 33, 0.2);
  color: #777D81;
}
.input:focus,
.textarea:focus {
  border-color: #97C221;
  box-shadow: 0 0 0.1875rem #97C221 inset, 0 0 0 0.1875rem rgba(151, 194, 33, 0.2);
  color: #777D81;
}
.input {
  height: 3.25rem;
  padding: 0 0.8em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
}
.textarea {
  line-height: 1.6;
  overflow: hidden;
  padding: 0.8em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.4em 0.8em 0.8em 0;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #97C221;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #97C221;
  box-shadow: 0 0 0.1875rem #97C221 inset, 0 0 0 0.1875rem rgba(151, 194, 33, 0.2);
  color: #777D81;
}
.control input:focus ~ .indicator {
  border-color: #97C221;
  box-shadow: 0 0 0.1875rem #97C221 inset, 0 0 0 0.1875rem rgba(151, 194, 33, 0.2);
  color: #777D81;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.8em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.0625rem solid #d8d8d8;
  border-radius: 0;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #777D81;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  background: #fff;
  border: 0.0625rem solid #d8d8d8;
  border-radius: 0;
  box-shadow: 0 0 0.1875rem #d8d8d8 inset;
  color: #777D81;
  cursor: pointer;
  display: block;
  margin: 0 0 1.06666667em;
  padding: 0 0.8em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #777D81;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.8em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #97C221;
}
.select.error.focus {
  border-color: #97C221;
  box-shadow: 0 0 0.1875rem #97C221 inset, 0 0 0 0.1875rem rgba(151, 194, 33, 0.2);
  color: #777D81;
}
.select.focus {
  border-color: #97C221;
  box-shadow: 0 0 0.1875rem #97C221 inset, 0 0 0 0.1875rem rgba(151, 194, 33, 0.2);
  color: #777D81;
}
.select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #777D81;
  cursor: pointer;
  height: calc(3.25rem - (0.0625rem * 2));
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.select select::-ms-expand {
  display: none;
}
.select select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #777D81;
}
.select select:focus::-ms-value {
  background: none;
  color: #777D81;
}
@-webkit-keyframes logo_arrow {
  0% {
    fill: #97C221;
  }
  20% {
    fill: #97C221;
  }
  30% {
    fill: #91999E;
  }
  100% {
    fill: #91999E;
  }
}
@keyframes logo_arrow {
  0% {
    fill: #97C221;
  }
  20% {
    fill: #97C221;
  }
  30% {
    fill: #91999E;
  }
  100% {
    fill: #91999E;
  }
}
@-webkit-keyframes header_image {
  0% {
    -webkit-transform: scale(1.06);
            transform: scale(1.06);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes header_image {
  0% {
    -webkit-transform: scale(1.06);
            transform: scale(1.06);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(151, 194, 33, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(151, 194, 33, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(151, 194, 33, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(151, 194, 33, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@media screen {
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  [role="tablist"] {
    margin-bottom: 1.6em;
  }
  * + [role="tablist"] {
    margin-top: 1.6em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    border-top: 0.0625rem solid #d8d8d8;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 100%;
    margin: 0 0 0.4em;
    min-width: 100%;
    padding: 0.53333333em 0;
  }
  [role="tab"] .title {
    flex-basis: 100%;
    margin-right: 0.8em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .icon {
    display: block;
    fill: #97C221;
    height: 1.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #97C221;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:focus {
    border-color: #97C221;
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #97C221;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.6em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 2.4em;
  }
  .contact_wrapper .address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact .min_width {
    display: inline-block;
    min-width: 4.375rem;
  }
  .contact strong {
    min-width: 5.625rem;
  }
  .address_vcard_button {
    display: inline-block;
    margin: 0 0 0.8em 0;
  }
  .address_vcard_button .icon {
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  }
  .address_vcard_button:focus .icon,
  .address_vcard_button:hover .icon {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
  .address_call_button {
    display: inline-block;
    margin: 0 0 0.8em 0;
  }
  .address_call_button:focus .icon,
  .address_call_button:hover .icon {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg);
  }
  aside {
    margin: 0 0 1.28em 0;
  }
  aside .center {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    text-align: center;
    -webkit-box-align: center;
            align-items: center;
  }
  aside .center hr {
    width: 100%;
  }
  aside .center .row {
    justify-content: center;
    -webkit-box-pack: center;
  }
  aside .center .row .contact {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
  }
  aside .center form {
    text-align: left;
  }
  aside .margin_bottom_big {
    margin-bottom: 1.6em;
  }
  .atikon_logo {
    height: 100%;
  }
  .author_hor {
    display: none;
  }
  .breadcrumbs {
    display: none;
  }
  .button {
    border-radius: 0;
    padding: 0 1.6em;
    text-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    background: #97C221;
    border: 0.0625rem solid #97C221;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 3.25rem;
  }
  .button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, transform 300ms;
    transition: fill 300ms, transform 300ms, -webkit-transform 300ms;
    width: 1.5rem;
  }
  .button .text {
    font-weight: 700;
    padding: 0.4em 0;
  }
  .button .icon + .text {
    padding-left: 0.53333333em;
  }
  .button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(151, 194, 33, 0.2);
    outline: 0;
    background: #7B9C20;
    border-color: #7B9C20;
    color: #fff;
  }
  .button:focus .icon {
    fill: #fff;
  }
  .button:hover {
    background: #7B9C20;
    border-color: #7B9C20;
    color: #fff;
  }
  .button:hover .icon {
    fill: #fff;
  }
  .button:active,
  .button.active {
    box-shadow: 0 0 0.1875rem #777D81 inset;
    background: #777D81;
    border: 0.0625rem solid #777D81;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button:active > span,
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 3.25rem;
  }
  .button:active .icon,
  .button.active .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, transform 300ms;
    transition: fill 300ms, transform 300ms, -webkit-transform 300ms;
    width: 1.5rem;
  }
  .button:active .text,
  .button.active .text {
    font-weight: 700;
    padding: 0.4em 0;
  }
  .button:active .icon + .text,
  .button.active .icon + .text {
    padding-left: 0.53333333em;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #97C221;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.4em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #97C221;
    height: auto;
    left: 0.4em;
    top: 0.4em;
    width: auto;
  }
  .chart_organigram {
    display: none;
  }
  .google-visualization-orgchart-table {
    border-bottom: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google-visualization-orgchart-table .node {
    background: #f6f6f6;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 85%;
    padding: 0.4375rem;
    vertical-align: middle;
  }
  .google-visualization-orgchart-table .google-visualization-orgchart-linenode {
    border-color: #d8d8d8;
    border-top: 0;
  }
  header .company_logo {
    width: 17.8125rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 2.24em;
    margin-right: auto;
    margin-top: 0.64em;
    text-decoration: none;
    -webkit-transition: margin 300ms ease-in-out, width 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: margin 300ms ease-in-out, width 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: margin 300ms ease-in-out, transform 300ms ease-in-out, width 300ms ease-in-out;
    transition: margin 300ms ease-in-out, transform 300ms ease-in-out, width 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  }
  header .company_logo .arrow_one {
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: logo_arrow;
            animation-name: logo_arrow;
    fill: #97C221;
    -webkit-transition: fill 300ms ease-in-out;
    transition: fill 300ms ease-in-out;
  }
  header .company_logo .arrow_two {
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: logo_arrow;
            animation-name: logo_arrow;
    fill: #91999E;
    -webkit-transition: fill 300ms ease-in-out;
    transition: fill 300ms ease-in-out;
  }
  header .company_logo .arrow_three {
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: logo_arrow;
            animation-name: logo_arrow;
    fill: #91999E;
    -webkit-transition: fill 300ms ease-in-out;
    transition: fill 300ms ease-in-out;
  }
  header .company_logo .arrow_four {
    -webkit-animation-delay: 4s;
            animation-delay: 4s;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-name: logo_arrow;
            animation-name: logo_arrow;
    fill: #91999E;
    -webkit-transition: fill 300ms ease-in-out;
    transition: fill 300ms ease-in-out;
  }
  header .company_logo:focus,
  header .company_logo:hover {
    text-decoration: none;
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: #f6f6f6;
    border-top: 0.125rem solid #d8d8d8;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9000;
  }
  .cookie_banner p {
    flex-basis: 100%;
    margin: 0;
    padding: 1.06666667em 0.8em 1.06666667em 1.6em;
  }
  .cookie_banner .close {
    cursor: pointer;
    flex-shrink: 0;
    margin: 0.8em;
    padding: 0 0.8em;
  }
  .cookie_banner .close > span {
    min-height: 3.25rem;
  }
  .cookie_banner .close .text {
    padding: 0 0.32em;
  }
  .download_overview {
    margin-bottom: 0.8em;
  }
  .download_overview .touch_link {
    display: -webkit-box;
    display: flex;
    margin-bottom: 0.8em;
    position: relative;
    z-index: 0;
  }
  .download_overview .touch_link .title {
    margin-bottom: 0.4em;
    padding-top: 0;
  }
  .download_overview .touch_link .icon {
    fill: #777D81;
    flex-shrink: 0;
    height: 3.125rem;
    margin-right: 1.28em;
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    width: 3.125rem;
  }
  .download_overview .touch_link::after {
    background-color: #f4f8e7;
    bottom: 0;
    content: "";
    left: -0.625rem;
    opacity: 0;
    position: absolute;
    right: -0.625rem;
    top: -0.625rem;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out;
    z-index: -1;
  }
  .download_overview .touch_link:focus::after,
  .download_overview .touch_link:hover::after {
    opacity: 1;
  }
  .download_overview .touch_link:focus .icon,
  .download_overview .touch_link:hover .icon {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
  }
  .dropdown_menu {
    display: none;
  }
  .dropdown_menu > ul {
    -webkit-box-align: stretch;
            align-items: stretch;
    display: -webkit-box;
    display: flex;
    position: relative;
  }
  .dropdown_menu > ul > li {
    -webkit-box-align: stretch;
            align-items: stretch;
    margin: 0;
    position: relative;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li.hover ul,
  .dropdown_menu > ul > li:focus ul,
  .dropdown_menu > ul > li:hover ul {
    opacity: 1;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  .dropdown_menu > ul > li > a {
    font-size: 0.9375rem;
    -webkit-box-align: center;
            align-items: center;
    color: #fff;
    display: -webkit-box;
    display: flex;
    font-weight: 400;
    height: 100%;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    letter-spacing: 0.01em;
    line-height: 1;
    margin: 0 0.16em;
    padding: 0 0.4em;
    position: relative;
    text-decoration: none;
    text-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
    -webkit-transition: color 300ms ease-in-out;
    transition: color 300ms ease-in-out;
    white-space: nowrap;
    z-index: 0;
  }
  .dropdown_menu > ul > li > a::after {
    background-color: #fff;
    bottom: 0.8125rem;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.1);
    content: "";
    height: 0.0625rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    right: 50%;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    z-index: -1;
  }
  .dropdown_menu > ul > li > a.active {
    color: #fff;
  }
  .dropdown_menu > ul > li > a.active::before {
    background-color: #777D81;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  .dropdown_menu > ul > li > a.active::after {
    background-color: #fff;
  }
  .dropdown_menu > ul > li > a:hover {
    outline: 0;
  }
  .dropdown_menu > ul > li > a:focus::after,
  .dropdown_menu > ul > li > a:hover::after {
    left: 1.44em;
    opacity: 1;
    right: 1.44em;
  }
  .dropdown_menu > ul > li ul {
    background: #fff;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.1);
    min-width: 20rem;
    opacity: 0;
    padding: 0.48em 1.28em;
    position: absolute;
    top: 2.88em;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transition: all 210ms ease-in-out;
    transition: all 210ms ease-in-out;
    z-index: 1000;
  }
  .dropdown_menu > ul > li ul li {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
  }
  .dropdown_menu > ul > li ul a {
    letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 0.875rem;
    color: #777D81;
    display: inline-block;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    line-height: 1.6;
    margin: 0.08em 0;
    padding: 0.16em 0;
    position: relative;
    text-align: left;
    text-decoration: none;
    -webkit-transition: color 300ms ease-in-out;
    transition: color 300ms ease-in-out;
    z-index: 0;
  }
  .dropdown_menu > ul > li ul a.active::after {
    background-color: #97C221;
    bottom: 0.1875rem;
    content: "";
    height: 0.0625rem;
    left: 0;
    max-width: 14.6875rem;
    opacity: 1;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
  .dropdown_menu > ul > li ul a:focus,
  .dropdown_menu > ul > li ul a:hover {
    color: #97C221;
  }
  .dropdown_menu > ul > li ul a:hover {
    outline: 0;
  }
  .dropdown_menu > ul > li:nth-child(2) ul {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 1.6em;
            column-gap: 1.6em;
    min-width: 45rem;
  }
  footer {
    position: relative;
  }
  footer .company_logo {
    width: 17.8125rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 0.48em;
    text-decoration: none;
  }
  footer .container {
    margin-bottom: 2.4em;
    margin-top: 2.4em;
  }
  footer .container .related_links {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    margin-top: 1.6em;
  }
  footer .container .related_links ul {
    display: -webkit-box;
    display: flex;
    padding: 0.64em 1.28em;
    position: relative;
    z-index: 0;
  }
  footer .container .related_links ul::after {
    background: -webkit-linear-gradient(left, #97C221 40%, #7B9C20 100%);
    background: linear-gradient(to right, #97C221 40%, #7B9C20 100%);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: -9.375rem;
    top: 0;
    z-index: -1;
  }
  footer .container .related_links ul li {
    line-height: 0;
  }
  footer .container .related_links ul li a {
    letter-spacing: calc((0.9375rem + 0.125
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 0.9375rem;
    border-left: 0.0625rem solid #fff;
    color: #fff;
    line-height: 1;
    padding: 0 0.8em;
    position: relative;
    text-decoration: none;
  }
  footer .container .related_links ul li a::after {
    background-color: #fff;
    bottom: 0;
    content: "";
    height: 0.0625rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    right: 50%;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  footer .container .related_links ul li a:focus::after,
  footer .container .related_links ul li a:hover::after {
    left: 0.8em;
    opacity: 1;
    right: 0.8em;
  }
  footer .container .related_links ul li:first-child a {
    border-left: 0;
  }
  footer .container .address .location {
    color: #97C221;
    margin-bottom: 0.48em;
  }
  footer .container .address .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  footer .container .address .contact_wrapper .address {
    flex-shrink: 0;
    margin-right: 0.8em;
    width: 9.375rem;
  }
  footer .container .address .contact_wrapper .more_contact strong {
    color: #97C221;
    font-weight: 400;
    min-width: 1.25rem;
  }
  footer .container .address .contact_wrapper .more_contact a {
    color: #777D81;
    position: relative;
    text-decoration: none;
    z-index: 0;
  }
  footer .container .address .contact_wrapper .more_contact a::after {
    background-color: #d8d8d8;
    bottom: 0.0625rem;
    content: "";
    height: 0.0625rem;
    left: 0;
    opacity: 1;
    position: absolute;
    right: 0;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  footer .container .address .contact_wrapper .more_contact a:focus::after,
  footer .container .address .contact_wrapper .more_contact a:hover::after {
    left: 50%;
    opacity: 0;
    right: 50%;
  }
  .form_disclaimer {
    background: #f6f6f6;
    border-radius: 0;
    color: #777D81;
    font-size: 85%;
    margin-bottom: 0.8em;
    padding: 1.06666667em 1.6em;
    text-align: center;
  }
  .form_disclaimer a {
    display: block;
  }
  .file {
    display: -webkit-box;
    display: flex;
    flex-basis: 65%;
    height: 3.25rem;
    margin: 0 0 1.06666667em;
    position: relative;
    z-index: 0;
  }
  .file.error .file_name {
    border-color: #97C221;
  }
  .file .file_name {
    background: #fff;
    border: 0.0625rem solid #d8d8d8;
    border-radius: 0 0 0 0;
    box-shadow: 0 0 0.1875rem #d8d8d8 inset;
    color: #777D81;
    -webkit-box-flex: 0;
            flex: 0 1 100%;
    height: 3.25rem;
    line-height: calc(3.25rem - (0.0625rem * 2));
    overflow: hidden;
    padding: 0 0.8em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 8.125rem;
  }
  .file .file_button {
    border-radius: 0 0 0 0;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    padding: 0 1.6em;
    z-index: 10;
    background: #97C221;
    border: 0.0625rem solid #97C221;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 3.25rem;
  }
  .file .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, transform 300ms;
    transition: fill 300ms, transform 300ms, -webkit-transform 300ms;
    width: 1.5rem;
  }
  .file .file_button .text {
    font-weight: 700;
    padding: 0.4em 0;
  }
  .file .file_button .icon + .text {
    padding-left: 0.53333333em;
  }
  .file .file_button.focus {
    box-shadow: 0 0 0 0.1875rem rgba(151, 194, 33, 0.2);
    background: #7B9C20;
    border-color: #7B9C20;
    color: #fff;
  }
  .file .file_button.focus .icon {
    fill: #fff;
  }
  .file [type="file"] {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 3.25rem;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
  }
  .file [type="file"]:hover ~ .file_button {
    background: #7B9C20;
    border-color: #7B9C20;
    color: #fff;
  }
  .file [type="file"]:hover ~ .file_button .icon {
    fill: #fff;
  }
  .file [type="file"]:active ~ .file_button {
    box-shadow: 0 0 0.1875rem #777D81 inset;
    background: #777D81;
    border: 0.0625rem solid #777D81;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file [type="file"]:active ~ .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 3.25rem;
  }
  .file [type="file"]:active ~ .file_button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, transform 300ms;
    transition: fill 300ms, transform 300ms, -webkit-transform 300ms;
    width: 1.5rem;
  }
  .file [type="file"]:active ~ .file_button .text {
    font-weight: 700;
    padding: 0.4em 0;
  }
  .file [type="file"]:active ~ .file_button .icon + .text {
    padding-left: 0.53333333em;
  }
  button {
    cursor: pointer;
    height: 3.25rem;
    margin: 0.8em 0;
    outline: 0;
    overflow: hidden;
    padding: 0 1.6em;
    width: 100%;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  .form.modular_form > fieldset {
    max-height: 0;
    overflow-y: hidden;
    -webkit-transition: all 900ms;
    transition: all 900ms;
    opacity: 0;
  }
  .form.modular_form > fieldset.show {
    max-height: none;
    opacity: 1;
  }
  .form.modular_form .button.nextpage,
  .form.modular_form .button.prevpage {
    cursor: pointer;
  }
  .form.modular_form input,
  .form.modular_form .select_wrapper {
    max-width: 26.25rem;
  }
  .form.modular_form .button.disabled {
    opacity: 0.5;
  }
  .form.modular_form .button[name="send"] {
    width: 100%;
  }
  .form.modular_form label {
    letter-spacing: calc((1.1875rem + 0.5
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1.1875rem;
    color: #777D81;
    font-family: "thierfeld-berg", sans-serif;
    font-weight: 300;
    line-height: 1.35;
    margin: 0 0 0.53333333em;
    padding-top: 0.8em;
    display: block;
    margin-bottom: 0.8em;
  }
  .form.modular_form .form_disclaimer {
    margin-top: 3.2em;
  }
  .progress_bar {
    height: 0.625rem;
    background: #f6f6f6;
    width: 100%;
    display: -webkit-box;
    display: flex;
    margin-bottom: 1.6em;
  }
  .progress_bar > .progress {
    -webkit-box-flex: 1;
            flex: 1;
  }
  .progress_bar > .progress.show {
    background: #97C221;
  }
  .checkbox.control {
    flex-wrap: wrap;
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    margin: 0.4em 0.8em 0.8em 0;
    position: relative;
  }
  .checkbox.control > .option {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-flex: 1;
            flex: 1 0 100%;
  }
  .checkbox.control > .option.button_option {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 0.8em 1.6em;
    line-height: 1;
    cursor: pointer;
    position: relative;
    -webkit-transform: all 300ms;
            transform: all 300ms;
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin-right: 1.6em;
    margin-bottom: 0.8em;
  }
  .checkbox.control > .option.button_option input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
  .checkbox.control > .option.button_option.notchecked {
    opacity: 0.5;
  }
  .checkbox.control > .option.button_option .indicator_wrapper {
    margin: 0 0 0.8em 0;
    position: initial;
  }
  .checkbox.control > .option.button_option .indicator {
    display: none;
  }
  .checkbox.control > .option.button_option .icon {
    display: block;
    width: 1.875rem;
    height: 1.875rem;
  }
  .required_description {
    margin-top: 1.6em;
  }
  .google_maps {
    margin: 0 0 0.8em;
  }
  .google_maps .map {
    background: #f6f6f6;
    border: 0.0625rem solid #e2e2e2;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map .gm-style > button {
    width: auto;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.8em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 2;
    background: #dddddd;
    border-color: #d0d0d0;
    color: #777D81;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #777D81;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.1875rem rgba(151, 194, 33, 0.2);
    z-index: 2;
    background: #b1dd39;
    border-color: #86ac1d;
    color: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 3.25rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    background: #fff;
    border: 0.0625rem solid #d8d8d8;
    color: #777D81;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 3.25rem;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #777D81;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, transform 300ms;
    transition: fill 300ms, transform 300ms, -webkit-transform 300ms;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .text {
    font-weight: 700;
    padding: 0.4em 0;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.53333333em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #777D81;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0 0 0 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0 0 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0;
    color: #97C221;
    font-size: 85%;
    margin-bottom: 0.8em;
    padding: 1.06666667em 1.6em;
  }
  .google_maps .direction {
    margin: 0.8em 0;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.8em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.0625rem solid #d8d8d8;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.0625rem solid #d8d8d8;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.8em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.8em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.8em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .google_maps .infowindow {
    max-width: 13.75rem;
  }
  .page {
    min-width: 20rem;
  }
  .container {
    margin: 0 auto;
    max-width: 87.5rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  header {
    position: relative;
  }
  header .top {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(255, 255, 255, 0.93);
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
            flex-grow: 1;
    position: relative;
    top: 0;
    width: 100%;
    padding-bottom: 1.625rem;
    -webkit-transition: padding-bottom 300ms;
    transition: padding-bottom 300ms;
  }
  header .top::after {
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    bottom: -0.5rem;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: -1;
  }
  header .top .container {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 100%;
    max-width: 100rem;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  header .menu_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    height: 3.25rem;
    width: 100%;
    position: absolute;
    z-index: 100;
    bottom: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  header .menu_wrapper .inner_menu_wrapper {
    position: relative;
    display: -webkit-box;
    display: flex;
  }
  header .menu_wrapper .inner_menu_wrapper::after {
    background: -webkit-linear-gradient(left, #97C221 0%, #7B9C20 100%);
    background: linear-gradient(to right, #97C221 0%, #7B9C20 100%);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  header .middle {
    position: relative;
  }
  header .middle .digitale_kanzlei {
    position: absolute;
    top: 3.5em;
    right: 2em;
    z-index: 1;
    width: 50px;
    height: 50px;
  }
  header .middle .digitale_kanzlei .mym-banner-container {
    width: 100% !important;
  }
  header .middle .smart_experts {
    position: absolute;
    top: 10em;
    right: 2em;
    z-index: 1;
    width: 100px;
    height: 100px;
  }
  header .middle .smart_experts img {
    background: rgba(255, 255, 255, 0.5);
  }
  header .middle .slider {
    line-height: 0;
    max-width: 100%;
    position: relative;
    z-index: 0;
  }
  header .middle .slider::after {
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
    bottom: 0;
    content: "";
    height: 0.75rem;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
  }
  header .middle .slider .slide {
    max-width: 100%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    -webkit-transition: opacity 600ms ease-in-out;
    transition: opacity 600ms ease-in-out;
    z-index: 0;
  }
  header .middle .slider .slide img {
    max-width: 100%;
    position: relative;
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  header .middle .slider .slide:first-child {
    position: relative;
  }
  header .middle .slider .slide.show {
    opacity: 1;
  }
  header .middle .slider .slide.show img {
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-name: header_image;
            animation-name: header_image;
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
  }
  header .middle .slider .slider_dots {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    bottom: 1.6em;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: nowrap;
    -webkit-box-pack: end;
            justify-content: flex-end;
    left: 50%;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%;
  }
  header .middle .slider .slider_dots .slider_dot,
  header .middle .slider .slider_dots .slider_next,
  header .middle .slider .slider_dots .slider_prev {
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    -webkit-filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.5));
            filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.5));
    height: 1.5625rem;
    margin: 0 0.53333333em;
    opacity: 0.8;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    width: 1.5625rem;
  }
  header .middle .slider .slider_dots .slider_dot {
    border: 0.25rem solid #fff;
  }
  header .middle .slider .slider_dots .slider_dot:focus,
  header .middle .slider .slider_dots .slider_dot:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  header .middle .slider .slider_dots .slider_dot.show {
    background-color: #fff;
    cursor: default;
  }
  header .middle .slider .slider_dots .slider_dot.show:focus,
  header .middle .slider .slider_dots .slider_dot.show:hover {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  header .middle .slider .slider_dots .slider_next,
  header .middle .slider .slider_dots .slider_prev {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  header .middle .slider .slider_dots .slider_next .icon,
  header .middle .slider .slider_dots .slider_prev .icon {
    display: block;
    fill: #fff;
    height: 100%;
    width: 100%;
  }
  header .middle .slider .slider_dots .slider_next:focus,
  header .middle .slider .slider_dots .slider_prev:focus,
  header .middle .slider .slider_dots .slider_next:hover,
  header .middle .slider .slider_dots .slider_prev:hover {
    -webkit-transform: scale(1.7);
            transform: scale(1.7);
  }
  header .middle .slider .slider_dots .slider_prev .icon {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .img_container.arbeitnehmer_online_svg_2_small {
    max-width: 18.125rem;
  }
  .img_container.arbeitnehmer_online_svg_2_small .svg_resize {
    padding-bottom: 634.48275862%;
  }
  .img_container.arbeitnehmer_online_svg_2 {
    margin: 0 0 1.6em;
    max-width: 28.125rem;
  }
  .img_container.arbeitnehmer_online_svg_2 .svg_resize {
    padding-bottom: 148.88888889%;
  }
  .arbeitnehmer_online_svg_2_small {
    display: block;
    margin: 1.6em auto;
  }
  .arbeitnehmer_online_svg_2 {
    display: none;
  }
  .arbeitnehmer_online_2_headline {
    fill: #777D81;
    font-family: "thierfeld-berg", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .arbeitnehmer_online_2_headline .strong {
    fill: #777D81;
    font-weight: 700;
  }
  .arbeitnehmer_online_text {
    fill: #777D81;
    font-family: "thierfeld-berg", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .arbeitnehmer_online_company_logo {
    fill: #777D81;
  }
  .arbeitnehmer_online_icon {
    fill: #97C221;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .arbeitnehmer_online_button .normal {
    display: block;
  }
  .arbeitnehmer_online_button .hover {
    display: none;
  }
  .arbeitnehmer_online_button:hover .normal {
    display: none;
  }
  .arbeitnehmer_online_button:hover .hover {
    display: block;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_icon {
    fill: #97C221;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_euro_icon,
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_warning_icon,
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_check_icon {
    fill: #97C221;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_icon {
    fill: #777D81;
  }
  .arbeitnehmer_online_lines {
    fill: #97C221;
  }
  .u_online_overview {
    margin-top: 1.6em;
  }
  .u_online_overview .touch_link {
    position: relative;
    z-index: 0;
  }
  .u_online_overview .touch_link .title::before {
    content: "» ";
  }
  .u_online_overview .touch_link::after {
    background-color: #f4f8e7;
    bottom: -0.8em;
    content: "";
    left: -0.8em;
    opacity: 0;
    position: absolute;
    right: -0.8em;
    top: -0.8em;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out;
    z-index: -1;
  }
  .u_online_overview .touch_link:hover::after,
  .u_online_overview .touch_link:focus::after {
    opacity: 1;
  }
  .u_online_secure_login {
    margin-bottom: 1.6em;
  }
  .img_container.u_online_svg_2_small {
    max-width: 18.125rem;
  }
  .img_container.u_online_svg_2_small .svg_resize {
    padding-bottom: 634.48275862%;
  }
  .img_container.u_online_svg_2 {
    margin: 0 0 1.6em;
    max-width: 28.125rem;
  }
  .img_container.u_online_svg_2 .svg_resize {
    padding-bottom: 148.88888889%;
  }
  .u_online_svg_2_small {
    display: block;
    margin: 1.6em auto;
  }
  .u_online_svg_2 {
    display: none;
  }
  .u_online_2_headline {
    fill: #777D81;
    font-family: "thierfeld-berg", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_2_headline .strong {
    fill: #777D81;
    font-weight: 700;
  }
  .u_online_text {
    fill: #777D81;
    font-family: "thierfeld-berg", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_company_logo {
    fill: #777D81;
  }
  .u_online_warning_icon {
    fill: #777D81;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_euro_icon,
  .u_online_check_icon {
    fill: #777D81;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_icon {
    fill: #97C221;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_button .normal {
    display: block;
  }
  .u_online_button .hover {
    display: none;
  }
  .u_online_button:hover .normal {
    display: none;
  }
  .u_online_button:hover .hover {
    display: block;
  }
  .u_online_button:hover .u_online_icon {
    fill: #97C221;
  }
  .u_online_button:hover .u_online_euro_icon,
  .u_online_button:hover .u_online_warning_icon,
  .u_online_button:hover .u_online_check_icon {
    fill: #97C221;
  }
  .u_online_button:hover .u_online_icon {
    fill: #777D81;
  }
  .u_online_lines {
    fill: #97C221;
  }
  .u_online_benefits_flow h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    text-align: center;
  }
  .u_online_benefits_flow .table_cell.text {
    margin: 0 auto;
    max-width: 20rem;
  }
  .u_online_benefits_flow .arrow_large {
    display: none;
  }
  .u_online_benefits_flow .arrow_small {
    display: block;
    height: 4rem;
    margin: 0 auto;
    width: 1.875rem;
  }
  .u_online_benefits_flow .flow_text {
    display: none;
  }
  .u_online_benefits_flow .u_online_arrow_main_color {
    fill: #97C221;
  }
  .u_online_benefits_flow .u_online_arrow_main_color_medium {
    fill: #cbe190;
  }
  .u_online_benefits_flow .header {
    display: table;
    margin: 0.8em auto;
    max-width: 20rem;
    text-align: right;
  }
  .u_online_benefits_flow .header .text {
    display: table-cell;
    margin: 0;
    padding: 0 0.8em 0 0;
    vertical-align: middle;
  }
  .u_online_benefits_flow .header .arrow_small {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .footer {
    display: table;
    margin: 0.8em auto;
    max-width: 20rem;
  }
  .u_online_benefits_flow .footer .text {
    display: table-cell;
    margin: 0;
    padding: 0 0 0 0.8em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .description {
    margin: 0 auto;
  }
  .u_online_benefits_flow .description ul {
    list-style: none;
    margin: 1.6em;
  }
  .u_online_benefits_flow .description li {
    margin: 0.8em 0;
    padding-left: 2.125rem;
    position: relative;
  }
  .u_online_benefits_flow .description li:before {
    background-color: #97C221;
    background-image: url("../images/u_online/u_online_check.svg");
    content: "";
    display: block;
    height: 1.5rem;
    left: 0;
    position: absolute;
    width: 1.5rem;
  }
  .u_online_benefits_flow .lock {
    background: #f6f6f6;
    display: table;
    margin: 0 auto 0.8em;
    padding: 1.6em;
  }
  .u_online_benefits_flow .lock .text {
    display: table-cell;
    padding-left: 1.6em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .lock:before {
    background: url("../images/u_online/u_online_lock.svg") no-repeat 50% 50%;
    content: "";
    display: table-cell;
    height: 1.75rem;
    width: 1.75rem;
  }
  .u_online_benefits_flow .benefits_button {
    border: 0;
    cursor: pointer;
    display: table;
    margin: 0 auto;
    min-width: 14.375rem;
    text-decoration: none;
  }
  .u_online_benefits_flow .benefits_button > span {
    display: table-cell;
  }
  .u_online_benefits_flow .benefits_button .text {
    border-bottom-width: 0.125rem;
    border-color: #97C221;
    border-left-width: 0.0625rem;
    border-radius: 0 0 0 0;
    border-style: solid;
    border-top-width: 0.0625rem;
    color: #777D81;
    display: block;
    font-size: 130%;
    font-weight: 500;
    line-height: 3.25rem;
    margin-right: 0.1875rem;
    padding: 0 1.6em;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: border 300ms;
    transition: border 300ms;
  }
  .u_online_benefits_flow .benefits_button:after {
    background-color: #97C221;
    background-image: url("../images/u_online/u_online_button_arrow.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 60%;
    border-radius: 0 0 0 0;
    content: "";
    display: table-cell;
    height: 3.25rem;
    position: relative;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    vertical-align: middle;
    width: 3.25rem;
    z-index: 1;
  }
  .u_online_benefits_flow .benefits_button.visible .text {
    border-color: #777D81;
  }
  .u_online_benefits_flow .benefits_button.visible:after {
    background-color: #777D81;
    border-radius: 0 0 0 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .benefits_button:hover .text {
    border-color: #75961a;
  }
  .u_online_benefits_flow .benefits_button:hover:after {
    background-color: #75961a;
  }
  .u_online_benefits_flow .flow_image {
    border: 0.25rem solid #97C221;
    box-sizing: content-box;
    height: 15.625rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 15.625rem;
  }
  .u_online_benefits_flow .flow_image .text {
    background: rgba(151, 194, 33, 0.85);
    bottom: 0;
    color: #fff;
    left: 0;
    padding: 0.8em 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .amount {
    display: block;
    text-align: right;
    white-space: nowrap;
  }
  .steuerlexikon .amount {
    display: table-cell;
  }
  .multi_columns li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .infolist_top_link {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 1.6em 0;
  }
  .infolist_top_link a {
    color: #777D81;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0;
    position: relative;
    text-align: center;
    text-decoration: none;
  }
  .infolist_top_link a::after {
    background-color: #777D81;
    bottom: 0;
    content: "";
    height: 0.0625rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    right: 50%;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .infolist_top_link a .icon {
    display: block;
    fill: #97C221;
    height: 2rem;
    margin: 0 auto;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    width: 2rem;
  }
  .infolist_top_link a:focus::after,
  .infolist_top_link a:hover::after {
    left: 20%;
    opacity: 1;
    right: 20%;
  }
  .infolist_top_link a:focus .icon,
  .infolist_top_link a:hover .icon {
    -webkit-transform: rotate(-90deg) scale(1.2);
            transform: rotate(-90deg) scale(1.2);
  }
  .disclaimer {
    background: #f6f6f6;
    border-radius: 0;
    color: #777D81;
    font-size: 85%;
    margin-bottom: 0.8em;
    padding: 1.06666667em 1.6em;
    text-align: center;
  }
  .disclaimer a {
    display: block;
  }
  .infolist_download {
    -webkit-box-align: center;
            align-items: center;
    background: #f6f6f6;
    border-radius: 0;
    color: #777D81;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.6em;
    padding: 1.6em;
  }
  .infolist_download .infolist_title {
    font-weight: 700;
  }
  .infolist_download p {
    margin-right: 1.6em;
  }
  .checklist_entry {
    border-top: 0.0625rem solid #d8d8d8;
    margin-bottom: 0.8em;
    padding-top: 0.8em;
  }
  .checklist_entry:first-child {
    border-top: 0;
    padding-top: 0;
  }
  .lexikon_liste a {
    color: #777D81;
  }
  .infolist_overview {
    margin-bottom: 0.8em;
  }
  .infolist_overview .touch_link {
    display: -webkit-box;
    display: flex;
    margin-bottom: 0.8em;
    position: relative;
    z-index: 0;
  }
  .infolist_overview .touch_link .icon {
    fill: #777D81;
    flex-shrink: 0;
    height: 3.125rem;
    margin-right: 1.28em;
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    width: 3.125rem;
  }
  .infolist_overview .touch_link::after {
    background-color: #f4f8e7;
    bottom: 0;
    content: "";
    left: -0.625rem;
    opacity: 0;
    position: absolute;
    right: -0.625rem;
    top: -0.625rem;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out;
    z-index: -1;
  }
  .infolist_overview .touch_link:focus::after,
  .infolist_overview .touch_link:hover::after {
    opacity: 1;
  }
  .infolist_overview .touch_link:focus .icon,
  .infolist_overview .touch_link:hover .icon {
    -webkit-transform: rotate(-14deg);
            transform: rotate(-14deg);
  }
  .job_overview .entry .title {
    text-decoration: underline;
  }
  .job_headlines h2,
  .job_headlines h3,
  .job_headlines h4,
  .job_headlines h5 {
    color: #97C221;
    font-weight: bold;
  }
  .job h1 {
    font-weight: bold;
  }
  .kanzlei_im_netz {
    margin: 0.8em 0;
  }
  .kanzlei_im_netz.some_overview {
    margin-bottom: 1.6em;
  }
  .kanzlei_im_netz.some_overview .touch_link {
    display: -webkit-box;
    display: flex;
  }
  .kanzlei_im_netz.some_overview .touch_link .description {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .kanzlei_im_netz.some_overview .touch_link .description .title {
    margin-bottom: 0;
  }
  .kanzlei_im_netz .touch_link {
    border-bottom: 0.0625rem solid #d8d8d8;
    margin-bottom: 1.6em;
    position: relative;
    z-index: 0;
  }
  .kanzlei_im_netz .touch_link::after {
    background-color: #f4f8e7;
    bottom: -0.16em;
    content: "";
    left: -0.48em;
    opacity: 0;
    position: absolute;
    right: -0.48em;
    top: -0.48em;
    -webkit-transition: opacity 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: opacity 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    z-index: -1;
  }
  .kanzlei_im_netz .touch_link:focus::after,
  .kanzlei_im_netz .touch_link:hover::after {
    opacity: 1;
  }
  .kanzlei_im_netz .touch_link:focus .icon,
  .kanzlei_im_netz .touch_link:hover .icon {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
  .kanzlei_im_netz .touch_link:last-child {
    border-bottom: 0;
  }
  .kanzlei_im_netz .icon {
    float: left;
    height: 3.625rem;
    margin: 0.4em 1.6em 0 0;
    padding: 0rem;
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    width: 3.625rem;
  }
  .kanzlei_im_netz .icon svg {
    height: 3.625rem;
    width: 3.625rem;
  }
  .kanzlei_im_netz .icon_video-camera {
    background: none;
    padding: 0.3125rem;
  }
  .kanzlei_im_netz .icon_video-camera svg {
    height: 3rem;
    width: 3rem;
  }
  .kanzlei_im_netz .icon_mail {
    background: #97C221;
    border-radius: 0;
    fill: #fff;
    padding: 0.3125rem;
  }
  .kanzlei_im_netz .icon_mail svg {
    height: 3rem;
    width: 3rem;
  }
  .kanzlei_im_netz .icon_instagram_sidebar {
    background: #E1306C;
    border-radius: 0;
    fill: #fff;
    padding: 0.375rem;
  }
  .kanzlei_im_netz .icon_instagram_sidebar svg {
    height: 2.875rem;
    width: 2.875rem;
  }
  .kanzlei_im_netz .icon.icon_linkedin {
    background: #0a66c2;
  }
  .kanzlei_im_netz .icon.icon_linkedin svg {
    padding: 0.3125rem;
  }
  .kanzlei_im_netz .description {
    display: block;
    overflow: hidden;
  }
  .main_wrapper {
    overflow-x: hidden;
  }
  .container {
    width: 100%;
  }
  .sitemap h2 a {
    text-decoration: none;
  }
  .sitemap ul li a {
    color: #777D81;
  }
  a.touch_link .title {
    text-decoration: none;
  }
  .sitemap,
  .column_two_all,
  .column_two ul,
  .column_two p {
    -webkit-column-gap: 0.9375rem;
            column-gap: 0.9375rem;
  }
  .sitemap li,
  .column_two_all li,
  .column_two ul li,
  .column_two p li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid-column;
  }
  .sitemap h1,
  .column_two_all h1,
  .column_two ul h1,
  .column_two p h1,
  .sitemap h2,
  .column_two_all h2,
  .column_two ul h2,
  .column_two p h2,
  .sitemap h3,
  .column_two_all h3,
  .column_two ul h3,
  .column_two p h3,
  .sitemap h4,
  .column_two_all h4,
  .column_two ul h4,
  .column_two p h4 {
    -webkit-column-fill: balance;
            column-fill: balance;
    -webkit-column-span: all;
            column-span: all;
  }
  .sitemap h1,
  .column_two_all h1,
  .column_two ul h1,
  .column_two p h1,
  .sitemap h2,
  .column_two_all h2,
  .column_two ul h2,
  .column_two p h2,
  .sitemap h3,
  .column_two_all h3,
  .column_two ul h3,
  .column_two p h3,
  .sitemap h4,
  .column_two_all h4,
  .column_two ul h4,
  .column_two p h4,
  .sitemap p,
  .column_two_all p,
  .column_two ul p,
  .column_two p p {
    -webkit-column-break-inside: auto;
            break-inside: auto;
  }
  .recent_video {
    margin-bottom: 1.6em;
  }
  main {
    margin: 1.28em 0 0 0;
  }
  main.main_margin_bottom {
    margin: 1.28em 0;
  }
  .startpage main h1 {
    max-width: 62.5rem;
  }
  .startpage main .button {
    margin-top: 1.6em;
  }
  .startpage main hr {
    margin-top: 3.2em;
  }
  .link_digitopia {
    z-index: 2;
    padding: 0.625rem;
  }
  .smart_experts {
    position: absolute;
    bottom: 15rem;
    right: 2rem;
  }
  .umgangsvereinbarung .teaser_overview_wrapper .description .icon {
    width: 3.125rem !important;
    height: 3.125rem !important;
  }
  .umgangsvereinbarung .teaser_overview_wrapper .description svg {
    fill: #97C221;
  }
  .umgangsvereinbarung.teaser_overview_wrapper {
    text-align: center;
  }
  .umgangsvereinbarung.teaser_overview_wrapper .description .icon {
    width: 3.125rem !important;
    height: 3.125rem !important;
  }
  .umgangsvereinbarung.teaser_overview_wrapper .description svg {
    fill: #97C221;
  }
  .startpage .umgangsvereinbarung.teaser_overview_wrapper {
    border: 0;
    margin: 0;
    padding: 0;
  }
  .heilberufe_content_img_1 {
    background: url("../images/heilberufe_bg_1_short.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: calc(-26vw + 25%);
    min-height: 20vh;
    margin-top: 1.6em;
    margin-bottom: 1.6em;
  }
  .heilberufe_content_img_2 {
    background: url("../images/heilberufe_bg_2.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: calc(-51vw + 50%);
    min-height: 18vh;
    margin-top: 1.6em;
    margin-bottom: 1.6em;
  }
  .pflegeberufe_content_img_1 {
    background: url("../images/pflegeberufe_bg_short.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: calc(-26vw + 25%);
    min-height: 20vh;
    margin-top: 1.6em;
    margin-bottom: 1.6em;
  }
  .fastdocs_img_link {
    display: inline-block;
    -webkit-transition: 300ms ease-in-out;
    transition: 300ms ease-in-out;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .fastdocs_img_link:after {
    content: '';
    position: absolute;
    right: -40px;
    top: 42%;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #97C221;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    clear: both;
    opacity: 0;
    -webkit-transition: 300ms ease-in-out;
    transition: 300ms ease-in-out;
  }
  .fastdocs_img_link figure {
    margin-bottom: 0;
  }
  .fastdocs_img_link:hover {
    -webkit-transform: translateX(0.53333333em);
            transform: translateX(0.53333333em);
  }
  .fastdocs_img_link:hover:after {
    opacity: 1;
  }
  .kundenstimme_teaser {
    border-top: 0.0625rem solid #d8d8d8;
    padding-top: 1.6em;
    margin-top: 3.2em;
  }
  .toggle_offcanvas_menu label {
    letter-spacing: calc((1.375rem + 0.5
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1.375rem;
    -webkit-box-align: center;
            align-items: center;
    color: #fff;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: ;
    font-weight: 400;
    height: 3rem;
    padding: 0 0.8em;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .icon {
    height: 1.875rem;
    margin-right: 0.48em;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 1.75rem;
  }
  .toggle_offcanvas_menu label .line {
    background: #fff;
    display: block;
    height: 0.125rem;
    position: absolute;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0.25rem;
    -webkit-transition: background 300ms, top 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, top 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, top 300ms 300ms, transform 300ms;
    transition: background 300ms, top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 0.8125rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0.375rem;
    -webkit-transition: background 300ms, bottom 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(255, 255, 255, 0.6);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 240ms;
    transition: opacity 240ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background-color: #fff;
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.2);
    display: block;
    min-height: 100%;
    opacity: 0;
    overflow-x: hidden;
    padding: 1.6em 0;
    position: absolute;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms, -webkit-transform 240ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 15.625rem;
    z-index: 5000;
    left: 0;
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    border-bottom: 0.0625rem solid #777D81;
    padding: 0;
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li:last-child {
    border-bottom: 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    color: #777D81;
    display: block;
    font-size: 1.1875rem;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    line-height: 3.125rem;
    padding: 0 0.64em;
    position: relative;
    text-decoration: none;
    -webkit-transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
    transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active {
    background-color: #777D81;
    color: #fff;
    text-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.25);
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:focus,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:hover {
    background-color: #97C221;
    color: #fff;
    text-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.25);
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    height: 0;
    margin-bottom: 0;
    overflow: hidden;
    -webkit-transition: height 300ms ease-in-out;
    transition: height 300ms ease-in-out;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul li {
    padding: 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    letter-spacing: calc((1.0625rem + 0.0625
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1.0625rem;
    color: #777D81;
    display: inline-block;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    padding: 0.32em 0.96em;
    text-decoration: none;
    -webkit-transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
    transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
    width: 100%;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    background-color: #777D81;
    color: #fff;
    text-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.25);
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a:focus,
  .offcanvas_menu .offcanvas_menu_content > ul ul a:hover {
    background-color: #97C221;
    color: #fff;
    text-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.25);
  }
  .onlinetools_popup_overview {
    margin-top: 2.4em;
  }
  .onlinetools_popup_overview .entry {
    border: 0.0625rem solid #d8d8d8;
    margin: 0 0 2.4em;
    padding: 1.6em;
  }
  .onlinetools_popup_overview .entry .title {
    padding: 0;
  }
  .onlinetools_popup_overview .entry .description {
    display: block;
    margin-bottom: 0.8em;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(255, 255, 255, 0.6);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.6em;
  }
  .popup_close {
    background: #97C221;
    border-radius: 0 0 0 0;
    float: right;
    height: 2.6rem;
    margin-right: 0.0625rem;
    padding: 0.45714286em;
    text-align: center;
    width: 3.25rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 87.5rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.3);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.6rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .open_street_map .map {
    background-color: #f6f6f6;
    cursor: move;
    height: 21.875rem;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(246, 246, 246, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.6em 3.2em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.6em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    margin: 1.6em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 1.6em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.8em;
  }
  .open_street_map .plan_route .form_disclaimer {
    margin-top: 1.6em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: #97C221;
    border: 0.0625rem solid #97C221;
    border-radius: 0;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 700;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: #7B9C20;
    border: 0.0625rem solid #7B9C20;
    box-shadow: 0 0 0 0.1875rem rgba(151, 194, 33, 0.2);
    color: #fff;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: #7B9C20;
    border: 0.0625rem solid #7B9C20;
    color: #fff;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none !important;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.8em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    background: #f6f6f6;
    border-radius: 0;
    color: #777D81;
    margin-bottom: 0.8em;
    padding: 1.06666667em 1.6em;
  }
  .opening_times .title {
    padding-top: 0.8em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 2.4em;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #d8d8d8;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  form[role="search"] {
    display: -webkit-box;
    display: flex;
  }
  form[role="search"] button {
    border-radius: 0 0 0 0;
    margin: 0;
    min-width: 3.25rem;
    padding: 0;
    width: 3.25rem;
    background: #97C221;
    border: 0.0625rem solid #97C221;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  form[role="search"] button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 3.25rem;
  }
  form[role="search"] button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, transform 300ms;
    transition: fill 300ms, transform 300ms, -webkit-transform 300ms;
    width: 1.5rem;
  }
  form[role="search"] button .text {
    font-weight: 700;
    padding: 0.4em 0;
  }
  form[role="search"] button .icon + .text {
    padding-left: 0.53333333em;
  }
  form[role="search"] button[disabled] {
    background-color: #f6f6f6;
    border-color: #e9e9e9;
    color: #fbfcfc;
  }
  form[role="search"] button[disabled] .icon {
    fill: #777D81;
  }
  form[role="search"] button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(151, 194, 33, 0.2);
    position: relative;
    z-index: 10;
    background: #7B9C20;
    border-color: #7B9C20;
    color: #fff;
  }
  form[role="search"] button:focus .icon {
    fill: #fff;
  }
  form[role="search"] button .icon {
    height: calc(3.25rem - (0.0625rem * 2));
  }
  input[type="search"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #fff;
    border: 0.0625rem solid #d8d8d8;
    border-radius: 0 0 0 0;
    box-shadow: 0 0 0 #d8d8d8 inset;
    color: #777D81;
    flex-basis: 100%;
    height: 3.25rem;
    margin: 0;
    padding: 0 0.8em;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  input[type="search"]:focus {
    background: #fff;
    border-color: #97C221;
    box-shadow: 0 0 0.1875rem #97C221 inset, 0 0 0 0.1875rem rgba(151, 194, 33, 0.2);
    color: #777D81;
    outline: 0;
    position: relative;
    z-index: 10;
  }
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  .search dl {
    margin: 0 0 0.8em;
    padding-top: 0.4em;
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .search dd {
    margin: 0;
  }
  .toggle_search_box {
    display: -webkit-box;
    display: flex;
    position: relative;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
    z-index: 10;
    height: 2.75rem;
    width: 2.75rem;
    margin-right: 1.6em;
  }
  .toggle_search_box .icon {
    height: 1.25rem;
    width: 1.25rem;
    display: block;
    fill: #fff;
  }
  .toggle_search_box .text {
    display: none;
  }
  .toggle_search_box .open_search_box {
    display: -webkit-box;
    display: flex;
  }
  .toggle_search_box .close_search_box {
    display: none;
  }
  .search_box {
    -webkit-backdrop-filter: blur(0.125rem);
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    padding: 0.8em;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 9999;
  }
  .search_box .close_search_box_wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .search_box .close_search_box_wrapper label {
    background: rgba(255, 255, 255, 0.6);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
  }
  .search_box .close_search_box_wrapper .close_search_box {
    background: #97C221;
    border: 0.0625rem solid #97C221;
    border-radius: 50%;
    box-shadow: 0 0 1.25rem rgba(119, 125, 129, 0.7);
    fill: #fff;
    height: 2.75rem;
    padding: 0.25rem;
    position: absolute;
    right: 1.6em;
    top: 1.6em;
    width: 2.75rem;
  }
  .search_box .close_search_box_wrapper .close_search_box .icon {
    display: block;
    height: 2.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.125rem;
  }
  .search_box .close_search_box_wrapper .close_search_box:focus {
    background: #7B9C20;
    border-color: #7B9C20;
    outline: 0;
  }
  .search_box form[role="search"] {
    background: #fff;
    border: 0.625rem solid #fff;
    border-radius: 0;
    box-shadow: 0 0 1.25rem rgba(119, 125, 129, 0.5);
    display: -webkit-box;
    display: flex;
    opacity: 0;
    position: relative;
    -webkit-transform: translateY(-6.25rem);
            transform: translateY(-6.25rem);
    -webkit-transition: none;
    transition: none;
    visibility: hidden;
    width: 37.5rem;
    z-index: 1000;
  }
  .search_box form[role="search"] button {
    -webkit-box-flex: 0;
            flex: 0 1 3.75rem;
    height: auto;
    padding: 0 0.4em;
  }
  .search_box input[type="search"] {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    font-size: 125%;
  }
  .search_box_state {
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
  }
  .search_box_state:checked ~ .search_box,
  .search_box_state:target ~ .search_box {
    opacity: 1;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box .close_search_box_wrapper label,
  .search_box_state:target ~ .search_box .close_search_box_wrapper label {
    opacity: 1;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: visible;
  }
  .search_box_state:checked ~ .search_box form[role="search"],
  .search_box_state:target ~ .search_box form[role="search"] {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .open_search_box {
    display: none;
  }
  .search_box_state:checked ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box,
  .search_box_state:target ~ .offcanvas_menu_wrapper .toggle_search_box .close_search_box {
    display: block;
  }
  .nice_slider {
    position: relative;
  }
  .nice_slider:focus {
    outline: 0;
  }
  .nice_slider .slides_wrapper {
    overflow: hidden;
  }
  .nice_slider .slides_wrapper .slides {
    display: -webkit-box;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
  }
  .nice_slider .slides_wrapper .slides [data-position="800"] {
    -webkit-transform: translateX(800%);
            transform: translateX(800%);
  }
  .nice_slider .slides_wrapper .slides [data-position="700"] {
    -webkit-transform: translateX(700%);
            transform: translateX(700%);
  }
  .nice_slider .slides_wrapper .slides [data-position="600"] {
    -webkit-transform: translateX(600%);
            transform: translateX(600%);
  }
  .nice_slider .slides_wrapper .slides [data-position="500"] {
    -webkit-transform: translateX(500%);
            transform: translateX(500%);
  }
  .nice_slider .slides_wrapper .slides [data-position="400"] {
    -webkit-transform: translateX(400%);
            transform: translateX(400%);
  }
  .nice_slider .slides_wrapper .slides [data-position="300"] {
    -webkit-transform: translateX(300%);
            transform: translateX(300%);
  }
  .nice_slider .slides_wrapper .slides [data-position="200"] {
    -webkit-transform: translateX(200%);
            transform: translateX(200%);
  }
  .nice_slider .slides_wrapper .slides [data-position="100"] {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  .nice_slider .slides_wrapper .slides [data-position="-800"] {
    -webkit-transform: translateX(-800%);
            transform: translateX(-800%);
  }
  .nice_slider .slides_wrapper .slides [data-position="-700"] {
    -webkit-transform: translateX(-700%);
            transform: translateX(-700%);
  }
  .nice_slider .slides_wrapper .slides [data-position="-600"] {
    -webkit-transform: translateX(-600%);
            transform: translateX(-600%);
  }
  .nice_slider .slides_wrapper .slides [data-position="-500"] {
    -webkit-transform: translateX(-500%);
            transform: translateX(-500%);
  }
  .nice_slider .slides_wrapper .slides [data-position="-400"] {
    -webkit-transform: translateX(-400%);
            transform: translateX(-400%);
  }
  .nice_slider .slides_wrapper .slides [data-position="-300"] {
    -webkit-transform: translateX(-300%);
            transform: translateX(-300%);
  }
  .nice_slider .slides_wrapper .slides [data-position="-200"] {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
  }
  .nice_slider .slides_wrapper .slides [data-position="-100"] {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  .nice_slider .slides_wrapper .slides .slide {
    opacity: 0;
    position: relative;
    visibility: hidden;
    width: 100%;
  }
  .nice_slider .slides_wrapper .slides .slide.no_grid {
    min-width: 100%;
  }
  .nice_slider .slides_wrapper .slides .slide.active {
    opacity: 1;
    visibility: visible;
  }
  .nice_slider .slides_wrapper .slides .slide.visible {
    bottom: 0;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    visibility: visible;
  }
  .nice_slider .slides_wrapper .slides .slide:focus {
    outline: 0;
  }
  .nice_slider .slide.animated {
    -webkit-transition: -webkit-transform 300 ease-out;
    transition: -webkit-transform 300 ease-out;
    transition: transform 300 ease-out;
    transition: transform 300 ease-out, -webkit-transform 300 ease-out;
  }
  .nice_slider.fade .slide.animated {
    -webkit-animation: slider_fade 600 ease-out forwards;
            animation: slider_fade 600 ease-out forwards;
    opacity: 0;
    -webkit-transition: unset;
    transition: unset;
  }
  .nice_slider.navigation_outside {
    padding: 0 1.875rem;
  }
  .nice_slider .navigation a {
    position: absolute;
    top: 30%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .nice_slider .navigation a .icon {
    display: block;
    fill: #777D81;
    height: 3.25rem;
    width: 3.25rem;
    -webkit-transition: fill 300ms ease-out;
    transition: fill 300ms ease-out;
  }
  .nice_slider .navigation a.prev {
    left: 1.25rem;
  }
  .nice_slider .navigation a.prev .icon {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .nice_slider .navigation a.next {
    right: 1.25rem;
  }
  .nice_slider .navigation a:hover .icon {
    fill: #777D81;
  }
  .nice_slider.pagination {
    margin-bottom: 3.2em;
  }
  .nice_slider .pagination {
    bottom: -3.2em;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 500;
  }
  .nice_slider .pagination a {
    border-radius: 50%;
    border: 0.0625rem solid #97C221;
    display: inline-block;
    height: 0.9375rem;
    margin: 0 0.125rem;
    width: 0.9375rem;
  }
  .nice_slider .pagination a.active,
  .nice_slider .pagination a:hover {
    background-color: #97C221;
  }
  .social_media_legal_notice {
    padding: 1.6em;
  }
  .social_media_legal_notice .company_logo {
    margin: 0 auto;
    max-width: 25rem;
    text-align: center;
  }
  .some_links ul {
    display: -webkit-box;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .some_links li {
    list-style: none;
    margin: 0;
    padding: 1.375rem 1.375rem 0.6875rem 0;
  }
  .some_links a {
    border-radius: 0;
    display: inline-block;
  }
  .some_links a .icon {
    display: block;
    fill: #777D81;
    height: 2.75rem;
    width: 2.75rem;
  }
  .some_links a.facebook .icon,
  .some_links a.twitter .icon {
    border-radius: 0;
  }
  .some_links a.instagram {
    background: #e1306c;
  }
  .some_links a.instagram .icon {
    fill: #fff;
  }
  .some_links a.linkedin {
    background: #0a66c2;
  }
  .some_links a.linkedin .icon {
    width: 3.125rem;
  }
  .some_links a.linkedin .icon,
  .some_links a.instagram .icon {
    padding: 0.3125rem;
  }
  .social_media_links ul {
    display: -webkit-box;
    display: flex;
    margin: 0;
    padding: 0;
  }
  .social_media_links li {
    list-style: none;
    margin: 0;
    padding: 0 0.8em 0.8em 0;
  }
  .social_media_links a {
    border-radius: 0;
    display: inline-block;
  }
  .social_media_links a .icon {
    display: block;
    fill: #fff;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_links a.facebook {
    background: #3664a2;
  }
  .social_media_links a.twitter {
    background: #1da1f2;
  }
  .social_media_links a.video {
    background: #97C221;
  }
  .social_media_share ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: 1.6em 0 0.8em 0;
    padding: 0;
  }
  .social_media_share li {
    list-style: none;
    margin: 0.4em 0;
    padding: 0;
  }
  .social_media_share a {
    color: #777D81;
    cursor: pointer;
    display: block;
    margin-right: 0.8em;
    padding-right: 0.8em;
    text-decoration: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .social_media_share a > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 3.25rem;
  }
  .social_media_share a .icon {
    border-radius: 0;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_share a .icon svg {
    fill: #fff;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_share a .text {
    font-weight: 400;
    padding-left: 0.8em;
    position: relative;
    z-index: 0;
  }
  .social_media_share a .text::after {
    background-color: #777D81;
    bottom: 0.0625rem;
    content: "";
    height: 0.0625rem;
    left: 0.8em;
    opacity: 0;
    position: absolute;
    right: 100%;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .social_media_share a .text:focus::after,
  .social_media_share a .text:hover::after {
    opacity: 1;
    right: 0;
  }
  .social_media_share a.facebook .icon {
    background: #3664a2;
  }
  .social_media_share a.twitter .icon {
    background: #1da1f2;
  }
  .social_media_share a.linkedin .icon {
    background: #0A66C2;
  }
  .social_media_share a.linkedin .icon svg {
    padding: 0.3125rem;
  }
  .social_media_share a.print {
    display: none;
  }
  .social_media_share a.print .icon {
    background: #97C221;
    text-align: center;
  }
  .social_media_share a.print .icon svg {
    height: 2.125rem;
    width: 1.5rem;
  }
  .social_media_legal_notice {
    padding: 1.6em;
  }
  .social_media_legal_notice .company_logo {
    margin: 0 auto;
    text-align: center;
  }
  .steuernews_article_slider {
    display: none;
  }
  .steuernews_navigation {
    margin: 1.6em 0;
  }
  .steuernews_navigation nav ul:after {
    clear: both;
  }
  .steuernews_navigation nav ul:after,
  .steuernews_navigation nav ul:before {
    content: "";
    display: table;
  }
  .steuernews_navigation nav li {
    float: left;
  }
  .steuernews_navigation nav a {
    display: inline-block;
    line-height: 1.6;
    margin-bottom: 0.53333333em;
    margin-right: 0.53333333em;
    padding: 0 1.6em;
  }
  .steuernews_content [href*="thema"] {
    color: #777D81;
  }
  .steuernews_preview {
    border-top: 0.0625rem solid #d8d8d8;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 0.8em;
    margin-top: 1.6em;
    padding-top: 0.8em;
  }
  .steuernews_preview > .title {
    width: 100%;
  }
  .steuernews_preview .touch_link {
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 0.8em;
    width: 100%;
  }
  .steuernews_preview .touch_link .image {
    border: 0.0625rem solid #e2e2e2;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-flex: 0;
            flex-grow: 0;
    flex-shrink: 0;
    line-height: 0;
    margin: 0 auto 0.8em 0;
    overflow: hidden;
  }
  .steuernews_preview .touch_link .image img {
    height: 100%;
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  }
  .steuernews_preview .touch_link .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .steuernews_preview .touch_link .teaser_text .title {
    letter-spacing: calc((1.0625rem + 0.3125
                                 * (100vw - 20rem)
                                 / (78.125 - 20))
                                 / 1000 * 0);
    font-size: 1.0625rem;
    position: relative;
    text-decoration: none;
  }
  .steuernews_preview .touch_link .teaser_text .title::after {
    background-color: #97C221;
    bottom: -0.25rem;
    content: "";
    height: 0.0625rem;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 100%;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }
  .steuernews_preview .touch_link .teaser_text .text {
    margin-bottom: 0;
  }
  .steuernews_preview .touch_link .video_play {
    position: relative;
  }
  .steuernews_preview .touch_link .video_play .icon {
    fill: #97C221;
    height: 3.125rem;
    left: 0;
    margin: -1.5625rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    -webkit-transition: fill 300ms ease-in-out;
    transition: fill 300ms ease-in-out;
  }
  .steuernews_preview .touch_link:focus .image img,
  .steuernews_preview .touch_link:hover .image img {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  .steuernews_preview .touch_link:focus .teaser_text,
  .steuernews_preview .touch_link:hover .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .steuernews_preview .touch_link:focus .teaser_text .title::after,
  .steuernews_preview .touch_link:hover .teaser_text .title::after {
    left: 0;
    opacity: 1;
    right: 40%;
  }
  .steuernews_preview .touch_link:focus .video_play .icon,
  .steuernews_preview .touch_link:hover .video_play .icon {
    fill: #777D81;
  }
  .steuernews_search {
    margin: 0 0 1.6em;
  }
  .steuernews_search .search {
    margin: 0 0 0.8em;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_entity .news_image {
    border: 0.0625rem solid #e2e2e2;
    line-height: 0;
    margin-bottom: 1.12em;
  }
  .steuernews_video_overview {
    border-top: 0.0625rem solid #d8d8d8;
    margin-bottom: 1.6em;
    margin-top: 1.6em;
    padding-top: 0.8em;
  }
  .steuernews_video_overview .more_steuernews_tv {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .steuernews_video_overview .entry {
    display: -webkit-inline-box;
    display: inline-flex;
    text-decoration: none;
    width: 100%;
  }
  .steuernews_video_overview .entry .image {
    margin-bottom: 0.8em;
    position: relative;
  }
  .steuernews_video_overview .entry .video_play {
    border: 0.0625rem solid #e2e2e2;
    overflow: hidden;
    position: relative;
  }
  .steuernews_video_overview .entry .video_play .icon {
    fill: #97C221;
    height: 3.125rem;
    left: 0;
    margin: -1.5625rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_video_overview .entry figcaption {
    color: #777D81;
    font-size: 100%;
  }
  .steuernews_video_overview .entry figure img {
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    width: 100%;
  }
  .steuernews_video_overview .entry:hover figure img,
  .steuernews_video_overview .entry:focus figure img {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  .steuernews_video_overview .current_steuernews_video {
    margin-bottom: 1.6em;
  }
  .steuernews_video_overview .current_steuernews_video .video_wrapper {
    margin-bottom: 0;
  }
  .steuernews_contact_person {
    background: #f6f6f6;
    border-radius: 0;
    margin-bottom: 1.6em;
    padding: 1.6em;
  }
  .steuernews_contact_person .team_entry {
    display: -webkit-box;
    display: flex;
  }
  .steuernews_contact_person .team_entry figure {
    display: block;
  }
  .steuernews_contact_person .contact_person_entry {
    height: auto;
    margin-top: 1.6em;
    position: relative;
  }
  .steuernews_contact_person .more_team_contacts {
    background: #fff;
    border-radius: 0;
    color: #97C221;
    display: block;
    line-height: 1.6;
    margin: 1em 0;
    padding: 0.8em;
    text-align: center;
  }
  .steuernews_newsletter_subscribe {
    border-radius: 0;
    margin-bottom: 1.6em;
    padding: 1.6em;
    position: relative;
    z-index: 0;
  }
  .steuernews_newsletter_subscribe::after {
    background: #f6f6f6;
    bottom: 0;
    content: "";
    left: -62.4375rem;
    position: absolute;
    right: -62.4375rem;
    top: 0;
    z-index: -1;
  }
  .steuernews_newsletter_subscribe .form_disclaimer {
    background-color: #fff;
  }
  .steuernews_sidebar {
    margin: 1.6em 0 0.8em;
  }
  .steuernews_current_issue {
    border-top: 0.0625rem solid #d8d8d8;
    margin-bottom: 0.8em;
    margin-top: 0.8em;
    padding-top: 0.8em;
  }
  .steuernews_current_issue ul {
    border-left: 0.0625rem solid #97C221;
    padding-left: 1.6em;
  }
  .steuernews_current_issue ul a {
    color: #777D81;
    text-decoration: none;
  }
  .steuernews_current_issue ul a.active {
    color: #97C221;
  }
  .steuernews_current_issue ul a:hover,
  .steuernews_current_issue ul a:focus {
    text-decoration: underline;
  }
  .steuernews_issues {
    border-top: 0.0625rem solid #d8d8d8;
    margin-top: 1.6em;
    padding-top: 0.8em;
  }
  .steuernews_issues .button {
    margin: 0 0.32em 0.48em 0;
  }
  .steuernews_issues .button .text {
    font-weight: 400;
    text-transform: none;
  }
  .highlight_keyword {
    background: #97C221;
    color: #fff;
    outline: 0.125rem solid #97C221;
  }
  .team_overview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 0.8em;
  }
  .team_overview .title {
    margin: 0 0 0.8em 0;
    padding: 0;
  }
  .team_overview .photo {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0 0 1.6em 0;
  }
  .team_overview .photo img {
    display: block;
    width: 100%;
  }
  .team_overview .info {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    margin-bottom: 0.8em;
    -webkit-hyphens: initial;
        -ms-hyphens: initial;
            hyphens: initial;
  }
  .team_overview .short_profile {
    border-top: 0.0625rem solid #d8d8d8;
    margin-top: 1.6em;
    padding-top: 1.6em;
  }
  .team_overview .buttons .button {
    background: #fff;
    border: 0.0625rem solid #97C221;
    color: #777D81;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    display: inline-block;
    margin: 0 0 0.8em 0;
    text-shadow: none;
  }
  .team_overview .buttons .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 3.25rem;
  }
  .team_overview .buttons .button .icon {
    fill: #777D81;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, -webkit-transform 300ms;
    transition: fill 300ms, transform 300ms;
    transition: fill 300ms, transform 300ms, -webkit-transform 300ms;
    width: 1.5rem;
  }
  .team_overview .buttons .button .text {
    font-weight: 700;
    padding: 0.4em 0;
  }
  .team_overview .buttons .button .icon + .text {
    padding-left: 0.53333333em;
  }
  .team_overview .buttons .button:focus,
  .team_overview .buttons .button:hover {
    background: #97C221;
    border-color: #97C221;
    color: #fff;
    text-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.2);
  }
  .team_overview .buttons .button:focus .icon,
  .team_overview .buttons .button:hover .icon {
    fill: #fff;
  }
  .team_overview .buttons .button:focus .icon,
  .team_overview .buttons .button:hover .icon {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
  .team_overview .buttons .call:focus .icon,
  .team_overview .buttons .call:hover .icon {
    -webkit-transform: rotate(24deg);
            transform: rotate(24deg);
  }
  .team_overview .contact strong {
    color: #97C221;
    font-weight: 400;
    min-width: 1.25rem;
  }
  .team_entry .photo {
    margin-bottom: 0.8em;
  }
  .team_entry .photo img {
    display: block;
  }
  .team_entry .info {
    margin-bottom: 0.8em;
  }
  .teaser_overview_wrapper {
    border-top: 0.0625rem solid #d8d8d8;
    margin-bottom: 1.92em;
    margin-top: 1.92em;
    padding-top: 1.92em;
  }
  .teaser_overview_wrapper .title_overview {
    color: #97C221;
  }
  .teaser_overview_wrapper .teaser_overview .wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    width: 100%;
  }
  .teaser_overview_wrapper .teaser_overview .wrapper .wrapper_inner {
    align-content: flex-start;
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
            justify-content: flex-start;
    -webkit-transition: -webkit-transform 900ms ease-in-out;
    transition: -webkit-transform 900ms ease-in-out;
    transition: transform 900ms ease-in-out;
    transition: transform 900ms ease-in-out, -webkit-transform 900ms ease-in-out;
    width: 100%;
  }
  .teaser_overview_wrapper .teaser_overview .wrapper .wrapper_inner .hide_title .title {
    display: none;
  }
  .teaser_overview_wrapper .teaser_overview .wrapper .teaser {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-shrink: 0;
    padding: 5% 0;
    position: relative;
    width: 100%;
    z-index: 0;
  }
  .teaser_overview_wrapper .teaser_overview .wrapper .teaser::after {
    background-color: #f4f8e7;
    bottom: 0;
    content: "";
    left: 0.53333333em;
    opacity: 0;
    position: absolute;
    right: 0.53333333em;
    top: 0;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out;
    z-index: -1;
  }
  .teaser_overview_wrapper .teaser_overview .wrapper .teaser .description {
    display: -webkit-box;
    display: flex;
  }
  .teaser_overview_wrapper .teaser_overview .wrapper .teaser .description .icon {
    display: -webkit-box;
    display: flex;
    flex-shrink: 0;
    height: 5.4375rem;
    margin-right: 1.6em;
    width: 5.4375rem;
  }
  .teaser_overview_wrapper .teaser_overview .wrapper .teaser .title {
    color: #777D81;
    margin-bottom: 0.8em;
  }
  .teaser_overview_wrapper .teaser_overview .wrapper .teaser:focus::after,
  .teaser_overview_wrapper .teaser_overview .wrapper .teaser:hover::after {
    opacity: 1;
  }
  .teaser_overview_wrapper .teaser_overview .navigation {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    margin-top: 1.6em;
    width: 100%;
  }
  .teaser_overview_wrapper .teaser_overview .navigation .nav_button {
    background-color: #fff;
    border: 0.0625rem solid #91999E;
    border-radius: 50%;
    cursor: pointer;
    display: -webkit-inline-box;
    display: inline-flex;
    height: 1.875rem;
    margin: 0 0.32em;
    -webkit-transition: border 300ms ease-in-out, background-color 300ms ease-in-out;
    transition: border 300ms ease-in-out, background-color 300ms ease-in-out;
    width: 1.875rem;
  }
  .teaser_overview_wrapper .teaser_overview .navigation .nav_button:hover,
  .teaser_overview_wrapper .teaser_overview .navigation .nav_button:focus {
    background-color: #7B9C20;
    border: 0.0625rem solid #7B9C20;
  }
  .teaser_overview_wrapper .teaser_overview .navigation .nav_button.active {
    background-color: #97C221;
    border: 0.0625rem solid #97C221;
    cursor: default;
  }
  .teaser_overview_wrapper .teaser_overview .navigation .nav_button.active:hover,
  .teaser_overview_wrapper .teaser_overview .navigation .nav_button.active:focus {
    background-color: #97C221;
    border: 0.0625rem solid #97C221;
  }
  .teaser_overview_steuerberaterwechsel_wrapper {
    border-top: 0.0625rem solid #d8d8d8;
    margin-bottom: 1.92em;
    margin-top: 1.92em;
    padding-top: 1.92em;
  }
  .teaser_overview_steuerberaterwechsel_wrapper .title_overview {
    color: #97C221;
  }
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: hidden;
    width: 100%;
  }
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .wrapper .wrapper_inner {
    align-content: flex-start;
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
            justify-content: flex-start;
    -webkit-transition: -webkit-transform 900ms ease-in-out;
    transition: -webkit-transform 900ms ease-in-out;
    transition: transform 900ms ease-in-out;
    transition: transform 900ms ease-in-out, -webkit-transform 900ms ease-in-out;
    width: 100%;
  }
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .wrapper .steuerberterwechsel_teaser {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-shrink: 0;
    padding: 5% 0;
    position: relative;
    width: 100%;
    z-index: 0;
  }
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .wrapper .steuerberterwechsel_teaser::after {
    background-color: #f4f8e7;
    bottom: 0;
    content: "";
    left: 0.53333333em;
    opacity: 0;
    position: absolute;
    right: 0.53333333em;
    top: 0;
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out;
    z-index: -1;
  }
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .wrapper .steuerberterwechsel_teaser .description {
    display: -webkit-box;
    display: flex;
  }
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .wrapper .steuerberterwechsel_teaser .description .icon {
    display: -webkit-box;
    display: flex;
    flex-shrink: 0;
    height: 5.4375rem;
    margin-right: 1.6em;
    width: 5.4375rem;
  }
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .wrapper .steuerberterwechsel_teaser .title {
    color: #777D81;
    margin-bottom: 0.8em;
  }
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .wrapper .steuerberterwechsel_teaser:focus::after,
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .wrapper .steuerberterwechsel_teaser:hover::after {
    opacity: 1;
  }
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .navigation {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    margin-top: 1.6em;
    width: 100%;
  }
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .navigation .nav_button {
    background-color: #fff;
    border: 0.0625rem solid #91999E;
    border-radius: 50%;
    cursor: pointer;
    display: -webkit-inline-box;
    display: inline-flex;
    height: 1.875rem;
    margin: 0 0.32em;
    -webkit-transition: border 300ms ease-in-out, background-color 300ms ease-in-out;
    transition: border 300ms ease-in-out, background-color 300ms ease-in-out;
    width: 1.875rem;
  }
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .navigation .nav_button:hover,
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .navigation .nav_button:focus {
    background-color: #7B9C20;
    border: 0.0625rem solid #7B9C20;
  }
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .navigation .nav_button.active {
    background-color: #97C221;
    border: 0.0625rem solid #97C221;
    cursor: default;
  }
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .navigation .nav_button.active:hover,
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .navigation .nav_button.active:focus {
    background-color: #97C221;
    border: 0.0625rem solid #97C221;
  }
  .steuerberaterwechsel_intro {
    margin-bottom: 6.25rem;
    position: relative;
  }
  .steuerberaterwechsel_intro:before {
    content: "";
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    background: #97C221;
    left: 0.4375rem;
    position: absolute;
    bottom: -4.6875rem;
    border-radius: 50%;
  }
  .teaser_steuerberaterwechsel .wrapper_inner {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
            flex-direction: column !important;
  }
  .teaser_steuerberaterwechsel .wrapper_inner .teaser_wrapper {
    background: #f6f6f6;
    padding: 3.2em;
    margin-bottom: 1.6em;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    position: relative;
    min-height: 18.75rem;
    -webkit-box-align: center;
            align-items: center;
  }
  .teaser_steuerberaterwechsel .wrapper_inner .teaser_wrapper .icon {
    margin: 0 0 1.6em 0;
    min-width: 6.25rem;
    max-width: 6.25rem;
    max-height: 6.25rem;
  }
  .teaser_steuerberaterwechsel .wrapper_inner .teaser_wrapper .icon svg {
    min-width: 6.25rem;
    max-width: 6.25rem;
    max-height: 6.25rem;
    fill: #97C221;
  }
  .teaser_steuerberaterwechsel .wrapper_inner .teaser_wrapper .title {
    padding-top: 0;
  }
  .teaser_steuerberaterwechsel .wrapper_inner .teaser_wrapper:before {
    content: "";
    display: block;
    width: 0.3125rem;
    height: 3.75rem;
    background: #97C221;
    left: 1.5625rem;
    top: -6%;
    position: absolute;
  }
  .teaser_steuerberaterwechsel .wrapper_inner .teaser_wrapper:after {
    content: "";
    display: block;
    width: 0.3125rem;
    height: 3.75rem;
    background: #97C221;
    left: 1.5625rem;
    bottom: -7%;
    position: absolute;
  }
  .teaser_steuerberaterwechsel .wrapper_inner .teaser_wrapper:last-child:after {
    display: none;
  }
  .unternehmen_online_wrapper .title_overview {
    color: #97C221;
  }
  .unternehmen_online_wrapper .teaser_wrapper:hover {
    background: #f4f8e7;
  }
  .unternehmen_online_wrapper .teaser_wrapper svg {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  .unternehmen_online_wrapper .wrapper_inner {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .unternehmen_online_wrapper .wrapper_inner .teaser_wrapper {
    width: 100%;
  }
  .unternehmen_online_wrapper .wrapper_inner .teaser_wrapper a {
    padding-right: 0.625rem;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #f6f6f6;
    border: 0.0625rem solid #d8d8d8;
    border-radius: 0;
    color: #777D81;
    font-size: 85%;
    font-weight: 400;
    line-height: 2.0625rem;
    padding: 0 0.8em;
    position: absolute;
    left: 0;
    text-align: center;
    top: -2.4375rem;
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #f6f6f6;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #d8d8d8;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
  .recent_video .entry,
  .video_overview .entry,
  .video_infolists_overview .entry,
  .video_infolist_overview .entry,
  .video_tutorial_overview .entry {
    display: inline-block;
    text-decoration: none;
  }
  .recent_video .entry figure,
  .video_overview .entry figure,
  .video_infolists_overview .entry figure,
  .video_infolist_overview .entry figure,
  .video_tutorial_overview .entry figure {
    margin: 0.8em 0 0.8em 0;
  }
  .recent_video .entry figure img,
  .video_overview .entry figure img,
  .video_infolists_overview .entry figure img,
  .video_infolist_overview .entry figure img,
  .video_tutorial_overview .entry figure img {
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    width: 100%;
  }
  .recent_video .entry figcaption,
  .video_overview .entry figcaption,
  .video_infolists_overview .entry figcaption,
  .video_infolist_overview .entry figcaption,
  .video_tutorial_overview .entry figcaption {
    color: #777D81;
    font-size: 100%;
  }
  .recent_video .entry .video_play,
  .video_overview .entry .video_play,
  .video_infolists_overview .entry .video_play,
  .video_infolist_overview .entry .video_play,
  .video_tutorial_overview .entry .video_play {
    border: 0.0625rem solid #e2e2e2;
    overflow: hidden;
    position: relative;
  }
  .recent_video .entry .video_play .icon,
  .video_overview .entry .video_play .icon,
  .video_infolists_overview .entry .video_play .icon,
  .video_infolist_overview .entry .video_play .icon,
  .video_tutorial_overview .entry .video_play .icon {
    fill: #97C221;
    height: 3.125rem;
    left: 0;
    margin: -1.5625rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    -webkit-transition: fill 300ms ease-in-out;
    transition: fill 300ms ease-in-out;
  }
  .recent_video .entry:hover figure img,
  .video_overview .entry:hover figure img,
  .video_infolists_overview .entry:hover figure img,
  .video_infolist_overview .entry:hover figure img,
  .video_tutorial_overview .entry:hover figure img,
  .recent_video .entry:focus figure img,
  .video_overview .entry:focus figure img,
  .video_infolists_overview .entry:focus figure img,
  .video_infolist_overview .entry:focus figure img,
  .video_tutorial_overview .entry:focus figure img {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  .recent_video .entry:hover .video_play .icon,
  .video_overview .entry:hover .video_play .icon,
  .video_infolists_overview .entry:hover .video_play .icon,
  .video_infolist_overview .entry:hover .video_play .icon,
  .video_tutorial_overview .entry:hover .video_play .icon,
  .recent_video .entry:focus .video_play .icon,
  .video_overview .entry:focus .video_play .icon,
  .video_infolists_overview .entry:focus .video_play .icon,
  .video_infolist_overview .entry:focus .video_play .icon,
  .video_tutorial_overview .entry:focus .video_play .icon {
    fill: #777D81;
  }
  .video_wrapper {
    height: 0;
    margin-bottom: 1.6em;
    padding-top: 56.25%;
    position: relative;
  }
  .video_wrapper video,
  .video_wrapper iframe {
    border: 0.0625rem solid #e2e2e2;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .video_wrapper video::cue {
    background: #000000;
    color: #ffffff;
  }
  a.button.video_channel {
    background: #97C221;
    border-color: #536b12;
    color: #fff;
  }
  a.button.video_channel .icon {
    fill: #fff;
  }
  .video_preview {
    margin-bottom: 0.8em;
  }
  .video_preview .entry {
    -webkit-box-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 1.6em;
  }
  .video_preview .entry figure {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.8em 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .video_preview .entry figure img {
    -webkit-transition: -webkit-transform 300ms ease-in-out;
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    width: 100%;
  }
  .video_preview .entry .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .video_preview .entry .video_play {
    border: 0.0625rem solid #e2e2e2;
    overflow: hidden;
    position: relative;
  }
  .video_preview .entry .video_play .icon {
    fill: #97C221;
    height: 3.125rem;
    left: 0;
    margin: -1.5625rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    -webkit-transition: fill 300ms ease-in-out;
    transition: fill 300ms ease-in-out;
  }
  .video_preview .entry:hover figure img,
  .video_preview .entry:focus figure img {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  .video_preview .entry:hover .video_play .icon,
  .video_preview .entry:focus .video_play .icon {
    fill: #777D81;
  }
  .kanzlei_video_page .teaser_overview_wrapper h2 {
    text-align: center;
  }
  .open_street_map .map {
    background-color: #f6f6f6;
    cursor: move;
    height: 21.875rem;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(246, 246, 246, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.6em 3.2em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.6em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    margin: 1.6em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 1.6em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.8em;
  }
  .open_street_map .plan_route .form_disclaimer {
    margin-top: 1.6em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: #97C221;
    border: 0.0625rem solid #97C221;
    border-radius: 0;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 700;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: #7B9C20;
    border: 0.0625rem solid #7B9C20;
    box-shadow: 0 0 0 0.1875rem rgba(151, 194, 33, 0.2);
    color: #fff;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: #7B9C20;
    border: 0.0625rem solid #7B9C20;
    color: #fff;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none !important;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.8em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    background: #f6f6f6;
    border-radius: 0;
    color: #777D81;
    margin-bottom: 0.8em;
    padding: 1.06666667em 1.6em;
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: #f6f6f6;
    border-top: 0.125rem solid #d8d8d8;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9000;
  }
  .cookie_banner p {
    flex-basis: 100%;
    margin: 0;
    padding: 1.06666667em 0.8em 1.06666667em 1.6em;
  }
  .cookie_banner .close {
    cursor: pointer;
    flex-shrink: 0;
    margin: 0.8em;
    padding: 0 0.8em;
  }
  .cookie_banner .close > span {
    min-height: 3.25rem;
  }
  .cookie_banner .close .text {
    padding: 0 0.32em;
  }
}
@media screen and (min-width: 35rem) {
  aside {
    margin: 0 0 2.24em 0;
  }
}
@media screen and (min-width: 78.125rem) {
  aside {
    margin: 0 0 4.16em 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .chart_organigram {
    display: block;
    margin-bottom: 0.8em;
  }
}
@media screen and (min-width: 20rem) {
  header .company_logo {
    width: calc(17.8125rem + 11.5625
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  header .company_logo {
    width: 29.375rem;
  }
}
@media screen and (min-width: 35rem) {
  header .company_logo {
    margin-bottom: 0.64em;
  }
}
@media screen and (min-width: 68.75rem) {
  header .company_logo {
    margin-left: 5.3125rem;
  }
}
@media screen and (min-width: 106.25rem) {
  header .company_logo {
    margin-left: 0;
  }
}
@media screen and (min-width: 68.75rem) {
  .scrolled_down header .company_logo {
    width: 9.96875rem;
  }
}
@media screen and (min-width: 68.75rem) and (min-width: 20rem) {
  .scrolled_down header .company_logo {
    width: calc(9.96875rem + 12.0625
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 68.75rem) and (min-width: 78.125rem) {
  .scrolled_down header .company_logo {
    width: 22.03125rem;
  }
}
@media screen and (min-width: 78.125rem) {
  .dropdown_menu {
    -webkit-box-align: stretch;
            align-items: stretch;
    display: -webkit-box;
    display: flex;
    align-self: flex-end;
    position: relative;
    z-index: 1;
    height: 3.25rem;
  }
}
@media screen and (min-width: 112.5rem) {
  .dropdown_menu > ul > li > a {
    font-size: 1rem;
  }
}
@media screen and (min-width: 20rem) {
  .dropdown_menu > ul > li ul a {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .dropdown_menu > ul > li ul a {
    font-size: 1rem;
  }
}
@media screen and (min-width: 20rem) {
  footer .company_logo {
    width: calc(17.8125rem + 11.5625
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  footer .company_logo {
    width: 29.375rem;
  }
}
@media screen and (min-width: 100rem) {
  footer .company_logo {
    margin-left: -3.125rem;
  }
}
@media screen and (min-width: 103.125rem) {
  footer .company_logo {
    margin-left: -6.75rem;
  }
}
@media screen and (min-width: 78.125rem) {
  footer .container .border_right {
    position: relative;
    z-index: 0;
  }
  footer .container .border_right::after {
    background-color: #d8d8d8;
    bottom: 0;
    content: "";
    position: absolute;
    right: 0.8em;
    top: 1.28em;
    width: 0.0625rem;
  }
}
@media screen and (min-width: 78.125rem) and (min-width: 93.75rem) {
  footer .container .border_right::after {
    right: 3.2em;
  }
}
@media screen and (min-width: 106.25rem) {
  footer .container .related_links {
    bottom: 0;
    margin-left: 0;
    margin-top: 0;
    position: absolute;
    right: 0;
  }
}
@media screen and (min-width: 20rem) {
  footer .container .related_links ul li a {
    font-size: calc(0.9375rem + 0.125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  footer .container .related_links ul li a {
    font-size: 1.0625rem;
  }
}
@media screen and (min-width: 30rem) {
  footer .container .address .contact_wrapper {
    flex-wrap: nowrap;
  }
}
@media screen and (min-width: 35rem) {
  footer .container .address .contact_wrapper .address {
    margin-right: 1.6em;
    width: 10rem;
  }
}
@media screen and (min-width: 78.125rem) {
  footer .container .address .contact_wrapper .address {
    margin: 0;
    width: 13.125rem;
  }
}
@media screen and (min-width: 78.125rem) {
  footer .container .address .contact_wrapper .more_contact {
    margin: 0;
  }
}
@media screen and (min-width: 35rem) {
  .formular .button_wrapper {
    text-align: right;
  }
}
@media screen and (min-width: 35rem) {
  button {
    width: auto;
  }
}
@media screen and (min-width: 20rem) {
  .form.modular_form label {
    font-size: calc(1.1875rem + 0.5
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .form.modular_form label {
    font-size: 1.6875rem;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .infowindow {
    max-width: calc(13.75rem + 8.75
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .google_maps .infowindow {
    max-width: 22.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2)
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4)
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4)
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 68.75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 78.125rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2)
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 68.75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 78.125rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 68.75rem) {
  header {
    padding-top: 8.125rem;
    -webkit-transition: padding 300ms ease-in-out;
    transition: padding 300ms ease-in-out;
  }
}
@media screen and (min-width: 68.75rem) {
  header .top {
    padding-bottom: 3.25rem;
  }
}
@media screen and (min-width: 160rem) {
  header .top::after {
    display: none;
  }
}
@media screen and (min-width: 68.75rem) {
  header .top {
    left: 50%;
    max-width: 160rem;
    position: fixed;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 3;
  }
}
@media screen and (min-width: 137.5rem) {
  header .top .container {
    max-width: 131.25rem;
  }
}
@media screen and (min-width: 78.125rem) {
  header .menu_wrapper {
    -webkit-box-pack: center;
            justify-content: center;
  }
}
@media screen and (min-width: 78.125rem) {
  header .menu_wrapper .inner_menu_wrapper::after {
    left: -100%;
    right: -100%;
  }
}
@media screen and (min-width: 350px) {
  header .middle .digitale_kanzlei {
    width: 80px;
    height: 80px;
  }
}
@media screen and (min-width: 35rem) {
  header .middle .digitale_kanzlei {
    width: 100px;
    height: 100px;
  }
}
@media screen and (min-width: 47.5rem) {
  header .middle .digitale_kanzlei {
    width: 120px;
    height: 120px;
  }
}
@media screen and (min-width: 78.125rem) {
  header .middle .digitale_kanzlei {
    width: 135px;
    height: 135px;
  }
}
@media screen and (min-width: 350px) {
  header .middle .smart_experts {
    width: 150px;
    height: 150px;
  }
}
@media screen and (min-width: 35rem) {
  header .middle .smart_experts {
    width: 170px;
    height: 170px;
  }
}
@media screen and (min-width: 47.5rem) {
  header .middle .smart_experts {
    width: 220px;
    height: 220px;
    top: 11em;
  }
}
@media screen and (min-width: 20rem) {
  header .middle .slider .slider_dots {
    padding: 0 calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  header .middle .slider .slider_dots {
    padding: 0 3.125rem;
    bottom: 2.4em;
  }
}
@media screen and (max-width: 47.4375rem) {
  header .middle .slider .slider_dots {
    display: none;
  }
}
@media screen and (min-width: 68.75rem) {
  .scrolled_down header {
    padding-top: 0;
  }
  .scrolled_down header .top {
    padding-bottom: 1.625rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.arbeitnehmer_online_svg_2_small {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.arbeitnehmer_online_svg_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5rem) {
  .arbeitnehmer_online_2_headline {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5rem) {
  .arbeitnehmer_online_text {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.u_online_svg_2_small {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .img_container.u_online_svg_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5rem) {
  .u_online_2_headline {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5rem) {
  .u_online_text {
    font-size: 80%;
  }
}
@media screen and (min-width: 61.875rem) {
  .u_online_benefits_flow .table {
    display: table;
    margin: 0 auto;
    padding: 0;
  }
  .u_online_benefits_flow .table_cell {
    display: table-cell;
    padding: 0 1.6em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .table_cell.text {
    max-width: 31.25rem;
  }
  .u_online_benefits_flow .arrow_large {
    display: block;
  }
  .u_online_benefits_flow .arrow_small {
    display: none;
  }
  .u_online_benefits_flow .flow_text {
    display: block;
  }
  .u_online_benefits_flow .header {
    display: block;
    text-align: center;
  }
  .u_online_benefits_flow .header .text {
    display: block;
    margin: 0 auto 0.8em;
    padding: 0;
    text-align: center;
    width: 80%;
  }
  .u_online_benefits_flow .footer {
    display: block;
    text-align: center;
  }
  .u_online_benefits_flow .footer .text {
    display: block;
    margin: 0.8em auto 0;
    padding: 0;
    text-align: center;
    width: 80%;
  }
  .u_online_benefits_flow .footer .arrow_large {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .description {
    max-height: none;
    overflow: none;
    padding: 0 0.8em;
  }
  .u_online_benefits_flow .lock {
    margin-bottom: 1.6em;
  }
  .u_online_benefits_flow .lock:before {
    height: 3.5rem;
    width: 3.5rem;
  }
  .u_online_benefits_flow .benefits_button {
    display: none;
  }
}
@media screen and (min-width: 47.5rem) {
  .multi_columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 3.2em;
            column-gap: 3.2em;
  }
}
@media screen and (min-width: 20rem) {
  .sitemap,
  .column_two_all,
  .column_two ul,
  .column_two p {
    -webkit-column-gap: calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
            column-gap: calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (78.125 - 20));
  }
}
@media screen and (min-width: 61.875rem) {
  .sitemap,
  .column_two_all,
  .column_two ul,
  .column_two p {
    -webkit-column-count: 2;
            column-count: 2;
  }
}
@media screen and (min-width: 78.125rem) {
  .sitemap,
  .column_two_all,
  .column_two ul,
  .column_two p {
    -webkit-column-gap: 3.125rem;
            column-gap: 3.125rem;
  }
}
@media screen and (max-width: 68.6875rem) {
  .hideme_smaller_xxlarge {
    display: none;
  }
}
@media screen and (min-width: 61.875rem) {
  .recent_video .title {
    padding-top: 0;
  }
}
@media screen and (min-width: 35rem) {
  main {
    margin: 2.24em 0 0 0;
  }
}
@media screen and (min-width: 78.125rem) {
  main {
    margin: 4.16em 0 0 0;
  }
}
@media screen and (min-width: 35rem) {
  main.main_margin_bottom {
    margin: 2.24em 0;
  }
}
@media screen and (min-width: 78.125rem) {
  main.main_margin_bottom {
    margin: 4.16em 0;
  }
}
@media screen and (min-width: 78.125rem) {
  main .small_text_width {
    margin-right: 15rem;
  }
}
@media screen and (min-width: 30rem) {
  .link_digitopia {
    position: absolute;
    bottom: 0rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .heilberufe_content_img_1 {
    min-height: 35vh;
  }
}
@media screen and (min-width: 61.875rem) {
  .heilberufe_content_img_1 {
    min-height: 40vh;
    margin-top: 0em;
    margin-bottom: 0.8em;
  }
}
@media screen and (min-width: 47.5rem) {
  .heilberufe_content_img_2 {
    min-height: 35vh;
  }
}
@media screen and (min-width: 61.875rem) {
  .heilberufe_content_img_2 {
    min-height: 37vh;
  }
}
@media screen and (min-width: 47.5rem) {
  .pflegeberufe_content_img_1 {
    min-height: 35vh;
  }
}
@media screen and (min-width: 61.875rem) {
  .pflegeberufe_content_img_1 {
    min-height: 40vh;
    margin-top: 0em;
    margin-bottom: 0.8em;
  }
}
@media screen and (min-width: 78.125rem) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (min-width: 20rem) {
  .toggle_offcanvas_menu label {
    font-size: calc(1.375rem + 0.5
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .toggle_offcanvas_menu label {
    font-size: 1.875rem;
  }
}
@media screen and (min-width: 30rem) {
  .toggle_offcanvas_menu label {
    padding: 0 1.12em;
  }
}
@media screen and (min-width: 35rem) {
  .toggle_offcanvas_menu label {
    padding: 0 1.44em;
  }
}
@media screen and (max-width: 78.0625rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_1 {
    top: 0.8125rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: background 300ms, top 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, top 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, top 300ms, transform 300ms 300ms;
    transition: background 300ms, top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_3 {
    bottom: 0.8125rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: background 300ms, bottom 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .close_offcanvas_menu {
    display: block;
  }
}
@media screen and (max-width: 78.0625rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_1 {
    top: 0.8125rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: background 300ms, top 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, top 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, top 300ms, transform 300ms 300ms;
    transition: background 300ms, top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_3 {
    bottom: 0.8125rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: background 300ms, bottom 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .close_offcanvas_menu {
    display: block;
  }
}
@media screen and (min-width: 30rem) {
  .offcanvas_menu {
    width: 21.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    font-size: calc(1.0625rem + 0.0625
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.6em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.6em 0 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 106.25rem) {
  .nice_slider.navigation_outside {
    padding: 0;
  }
}
@media screen and (min-width: 106.25rem) {
  .nice_slider .navigation a.prev {
    left: auto;
    right: 100%;
  }
}
@media screen and (min-width: 106.25rem) {
  .nice_slider .navigation a.next {
    right: auto;
    left: 100%;
  }
}
@media screen and (min-width: 30rem) {
  .social_media_share a.print {
    display: block;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_article_slider {
    display: block;
    height: 16.125rem;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: relative;
    margin-bottom: 1.6em;
    z-index: 1;
  }
  .steuernews_article_slider::before {
    background-color: #f4f8e7;
    bottom: 0;
    content: "";
    left: -62.4375rem;
    position: absolute;
    right: -62.4375rem;
    top: 0;
    z-index: 0;
  }
  .steuernews_article_slider .slide {
    display: -webkit-box;
    display: flex;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms ease-in-out, visibilty 300ms ease-in-out;
    transition: opacity 300ms ease-in-out, visibilty 300ms ease-in-out;
    visibility: hidden;
    z-index: 0;
  }
  .steuernews_article_slider .slide .image {
    border: 0.0625rem solid #e2e2e2;
    line-height: 0;
    min-width: 24.1875rem;
    width: 24.1875rem;
  }
  .steuernews_article_slider .slide .description {
    padding: 0 4.8em 0 3.2em;
    width: 100%;
  }
  .steuernews_article_slider .slide .description .title {
    margin: 0;
  }
  .steuernews_article_slider .slide .description .sub_title {
    display: block;
    margin-bottom: 0.8em;
  }
  .steuernews_article_slider .slide_anchor:checked + .slide {
    opacity: 1;
    visibility: visible;
    z-index: 5;
  }
  .steuernews_article_slider .control_slides {
    align-content: center;
    -webkit-box-align: center;
            align-items: center;
    background: #97C221;
    display: -webkit-box;
    display: flex;
    fill: #fff;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: nowrap;
    height: 3.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    line-height: 0;
    margin-top: -1.875rem;
    padding: 1.06666667em;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 3.75rem;
    z-index: 15;
  }
  .steuernews_article_slider .control_slides svg {
    width: 1.125rem;
  }
  .steuernews_article_slider .control_slides:focus,
  .steuernews_article_slider .control_slides:hover {
    background-color: #7B9C20;
    outline: 0;
  }
  .steuernews_article_slider .prev_slide {
    left: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .steuernews_article_slider .next_slide {
    right: 0;
  }
}
@media screen and (min-width: 35rem) {
  .steuernews_preview .touch_link {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_preview .touch_link {
    width: 48%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
}
@media screen and (min-width: 78.125rem) {
  .steuernews_preview .touch_link {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 35rem) {
  .steuernews_preview .touch_link .image {
    margin-bottom: auto;
    margin-right: 0.96em;
    max-width: 16.25rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .steuernews_preview .touch_link .image {
    max-width: 100%;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_preview .touch_link .image {
    margin-bottom: 1.12em;
    margin-right: auto;
  }
}
@media screen and (min-width: 78.125rem) {
  .steuernews_preview .touch_link .image {
    margin-bottom: auto;
    margin-right: 1.28em;
    max-width: 16.25rem;
  }
}
@media screen and (min-width: 87.5rem) {
  .steuernews_preview .touch_link .image {
    max-width: 100%;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_preview .touch_link .teaser_text .title {
    font-size: calc(1.0625rem + 0.3125
                            * (100vw - 20rem)
                            / (78.125 - 20));
  }
}
@media screen and (min-width: 78.125rem) {
  .steuernews_preview .touch_link .teaser_text .title {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 35rem) {
  .news_entity .news_image {
    float: right;
    margin-left: 1.6em;
    max-width: 15.625rem;
    margin-top: 1.6em;
  }
}
@media screen and (min-width: 35rem) {
  .news_entity .news_image {
    float: right;
    margin-left: 1.6em;
    max-width: 21.875rem;
    margin-top: 1.6em;
  }
}
@media screen and (min-width: 68.75rem) {
  .news_entity .news_image {
    max-width: 100%;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_video_overview .more_steuernews_tv {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
}
@media screen and (min-width: 30rem) {
  .steuernews_video_overview .entry {
    width: 47%;
    margin-right: 3%;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_video_overview .entry {
    width: 100%;
    margin-right: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .steuernews_video_overview .current_steuernews_video {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .teaser_overview_wrapper .teaser_overview .wrapper {
    margin-left: -2.5%;
  }
}
@media screen and (min-width: 78.125rem) {
  .teaser_overview_wrapper .teaser_overview .wrapper {
    margin-left: -1.66666667%;
  }
}
@media screen and (min-width: 47.5rem) {
  .teaser_overview_wrapper .teaser_overview .wrapper .teaser {
    padding: 0 2.5%;
    width: 50%;
  }
}
@media screen and (min-width: 78.125rem) {
  .teaser_overview_wrapper .teaser_overview .wrapper .teaser {
    padding: 0 1.66666667%;
    width: 33.33333333%;
  }
}
@media screen and (min-width: 78.125rem) {
  .teaser_overview_wrapper .teaser_overview .wrapper .teaser::before {
    background-color: #d8d8d8;
    bottom: 0;
    content: "";
    position: absolute;
    right: -0.048em;
    top: 0;
    width: 0.0625rem;
    z-index: 1;
  }
}
@media screen and (min-width: 47.5rem) {
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .wrapper {
    margin-left: -2.5%;
  }
}
@media screen and (min-width: 78.125rem) {
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .wrapper {
    margin-left: -1.66666667%;
  }
}
@media screen and (min-width: 47.5rem) {
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .wrapper .steuerberterwechsel_teaser {
    padding: 0 2.5%;
    width: 50%;
  }
}
@media screen and (min-width: 78.125rem) {
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .wrapper .steuerberterwechsel_teaser {
    padding: 0 1.66666667%;
    width: 33.33333333%;
  }
}
@media screen and (min-width: 78.125rem) {
  .teaser_overview_steuerberaterwechsel_wrapper .teaser_overview .wrapper .steuerberterwechsel_teaser::before {
    background-color: #d8d8d8;
    bottom: 0;
    content: "";
    position: absolute;
    right: -0.048em;
    top: 0;
    width: 0.0625rem;
    z-index: 1;
  }
}
@media screen and (min-width: 35rem) {
  .steuerberaterwechsel_intro:before {
    bottom: -5rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .steuerberaterwechsel_intro:before {
    left: 5.8125rem;
  }
}
@media screen and (min-width: 35rem) {
  .teaser_steuerberaterwechsel .wrapper_inner .teaser_wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 35rem) {
  .teaser_steuerberaterwechsel .wrapper_inner .teaser_wrapper .icon {
    margin: 0 1.6em 0 0;
  }
}
@media screen and (min-width: 35rem) {
  .teaser_steuerberaterwechsel .wrapper_inner .teaser_wrapper:before {
    width: 0.3125rem;
    height: 6.25rem;
    top: -6%;
  }
}
@media screen and (min-width: 47.5rem) {
  .teaser_steuerberaterwechsel .wrapper_inner .teaser_wrapper:before {
    width: 0.3125rem;
    height: 6.25rem;
    top: -12%;
    left: 6.875rem;
  }
}
@media screen and (min-width: 35rem) {
  .teaser_steuerberaterwechsel .wrapper_inner .teaser_wrapper:after {
    width: 0.3125rem;
    height: 6.25rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .teaser_steuerberaterwechsel .wrapper_inner .teaser_wrapper:after {
    width: 0.3125rem;
    height: 5rem;
    left: 6.875rem;
  }
}
@media screen and (min-width: 68.75rem) {
  .unternehmen_online_wrapper .title_overview {
    padding-top: 0;
  }
}
@media screen and (min-width: 68.75rem) {
  .unternehmen_online_wrapper .wrapper_inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
}
@media screen and (min-width: 35rem) {
  .unternehmen_online_wrapper .wrapper_inner .teaser_wrapper {
    width: 50%;
  }
}
@media screen and (min-width: 47.5rem) {
  .unternehmen_online_wrapper .wrapper_inner .teaser_wrapper {
    width: 33%;
  }
}
@media screen and (min-width: 68.75rem) {
  .unternehmen_online_wrapper .wrapper_inner .teaser_wrapper {
    width: 100%;
    padding-right: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .video_preview .entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 61.875rem) {
  .video_preview .entry figure {
    flex-shrink: 0;
    margin-bottom: 0;
    margin-right: 1.6em;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.6em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.6em 0 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media print {
  hr {
    display: none!important;
  }
  .company_logo {
    display: block;
    margin-bottom: 0.8em;
    max-width: 17.5rem !important;
  }
  .company_logo .arrow_one {
    fill: #97C221;
  }
  .company_logo .arrow_two {
    fill: #91999E;
  }
  .company_logo .arrow_three {
    fill: #91999E;
  }
  .company_logo .arrow_four {
    fill: #91999E;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.6em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 700;
    margin-bottom: 0.8em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    display: block;
    font-size: 85%;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.6em;
  }
  .company_logo {
    display: block;
    max-width: 18.75rem;
  }
  .company_logo img {
    display: block;
  }
  fieldset {
    margin-bottom: 1.6em;
    page-break-inside: avoid;
  }
  .control {
    display: block;
  }
  .control .option {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: start;
            justify-content: flex-start;
    margin-bottom: 0.625rem;
  }
  .qr_code_print {
    border-top: 0.0625rem solid;
    margin: 1.6em 0 0;
    padding: 0.8em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
